import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuestionBase } from '../../classes/question-base';
import { FormGroup } from '@angular/forms';
import { QuestionControlService } from '../../services/question-control.service';
import { QuestionService } from 'src/app/modules/Invoices/services/question.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css'],
  providers: [ QuestionControlService ]
})
export class DynamicFormComponent {

  @Input() questions: QuestionBase<any>[] = [];
  @Output() formValue = new EventEmitter<any>();
  responseValidation: any = null;



  constructor(private qcs: QuestionControlService, public qService: QuestionService) {  }

  ngOnInit() {
    this.qService.form = this.qcs.toFormGroup(this.questions);
    this.qService.responseValidation$.subscribe(data => {
      this.responseValidation = data
    });
  }

  onSubmit() {
    const formData = this.qService.form.value;
    this.formValue.emit(formData);
  }

}
