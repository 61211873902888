import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private modalSubject = new Subject<IModalData | null>();

  constructor() { }

  showMessage(title:string,message: string, showButtons: boolean = false) {
    const modalData: IModalData = {
      title,
      message,
      showButtons
    }
    this.modalSubject.next(modalData);
  }

  closeModal() {
    this.modalSubject.next(null);
  }
  
  getModalObservable() {
    return this.modalSubject.asObservable();
  }

}


export interface IModalData{
  alertType?: AlertType;
  title:string;
  message:string;
  showButtons?: boolean;
}

export enum AlertType {
  message = 1
}