<!-- TextInput -->
<div *ngIf="question.controlType =='textbox'"  [formGroup]="form" class="mt-4">
  <label [for]="question.key" class="form-label col">
    {{question.label}} <button *ngIf="question?.showTooltip" type="button" class="btn btn-primary buttonTooltip" (click)="onEventTooltipHelp(question.event?.tooltipHelpButton)"><i class="bi bi-question-circle-fill"></i></button>
  </label>
  <input  [formControlName]="question.key" [id]="question.key" [type]="question.type ?? 'text'" class="form-control" (change)="onInputChange(question.event?.onInputChange)" 
  (keyup)="onInputKeyUp(question.event?.onInputChange)" (keydown)="onInputKeyDown($event)">
  <small [id]="'help_'+question.key" class="form-text text-muted" *ngIf="question?.helpMessage">
    {{ question.helpMessage }}
    <!-- <pre *ngIf="form != null">
      {{ this.form.get(question.key)?.valid  }}
    </pre> -->
  </small>
</div>

<!-- Dropdwn -->
<div *ngIf="question.controlType =='dropdown'"  [formGroup]="form" class="mt-4" >
  <!-- Label -->
  <p class="mb-0">
    <label class="form-label col"> 
      {{  question.label }}
    </label>  
    <button *ngIf="question?.showTooltip" type="button" class="btn btn-primary buttonTooltip" (click)="onEventTooltipHelp(question.event?.tooltipHelpButton)"><i class="bi bi-question-circle-fill"></i></button>
  </p>

  <!-- Select -->
  <div>
    <select [formControlName]="question.key"
            [id]="question.key" class="form-select" 
            class="form-select" aria-label="Default select example">
      <option *ngFor="let opt of questionAsDropdown.advancedOptions.options" [value]="opt.key">{{opt.value}}</option>
    </select>
  </div>


  <!-- Help Message -->
  <small class="form-text text-muted" *ngIf="question?.helpMessage">
    {{ question.helpMessage }}
  </small>

</div>


<!-- Checkbox -->
<div *ngIf="question.controlType == 'checkbox' "  [formGroup]="form"  class="mt-4">
  <!-- General Label Checkbox -->
  <p class="mb-0">
    <label class="form-label col"> 
      {{ question.label }} 
    </label> <button *ngIf="question?.showTooltip" type="button" class="btn btn-primary buttonTooltip" (click)="onEventTooltipHelp(question.event?.tooltipHelpButton)"><i class="bi bi-question-circle-fill"></i></button>
  </p>

  <!-- InputCheckbox -->
  <div class="form-check pt-2">
    <input [formControlName]="question.key" [id]="question.key" type="checkbox" [value]="question.value" class="form-check-input"
      (change)="onInputChange(question.event?.onInputChange)">
    <p class="mb-0"> 
      <label class="form-label col" [for]="question.key" [ngClass]="{ 'disabledText': question.disabled }"> {{ questionAsCheckbox.advancedOptions.label }} </label>
    </p>
  </div>

  <!-- Help Message -->
  <small class="form-text text-muted" *ngIf="question?.helpMessage">
    {{ question.helpMessage }}
  </small>

</div>


<!-- Radio -->
<div *ngIf="question.controlType == 'radio' " [formGroup]="form" class="mt-4">
  <!-- General Label Radio -->
  <p class="mb-0">
    <label class="form-label col"> 
      {{ question.label }} 
    </label> <button *ngIf="question?.showTooltip" type="button" class="btn btn-primary buttonTooltip" (click)="onEventTooltipHelp(question.event?.tooltipHelpButton)"><i class="bi bi-question-circle-fill"></i></button>
  </p>

  <!-- hidden input -->
  <input type="hidden" [formControlName]="question.key" [value]="question.value">

  <div  class="form-check pt-2">
    <div class="form-check" *ngFor="let item of questionAsRadio.advancedOptions.options">
      <input class="form-check-input" type="radio"  [name]="question.key"  [id]="item.key" (change)="onRadioChange(question.key, item.key)">
      <label class="form-check-label" [for]="item.key">
        {{ item.label}}
      </label>
    </div>
  </div>

  <!-- Help Message -->
  <small class="form-text text-muted" *ngIf="question?.helpMessage">
    {{ question.helpMessage }}
  </small>

</div>

<!-- Date -->
<div *ngIf="question.controlType == 'date' " [formGroup]="form" class="mt-4">

  <!-- General Label Checkbox -->
  <p class="mb-0">
    <label class="form-label col"> 
      {{ question.label }} 
    </label> <button *ngIf="question?.showTooltip" type="button" class="btn btn-primary buttonTooltip" (click)="onEventTooltipHelp(question.event?.tooltipHelpButton)"><i class="bi bi-question-circle-fill"></i></button>
  </p>

  <!-- Input Date -->
  <div class="form-group mb-1" >
    <input [formControlName]="question.key" [value]="question.value"  [id]="question.key"  type="date" class="form-control" 
    [min]="questionAsDate.advancedOptions.minDate ?? '1901-12-13'"
    [max]="questionAsDate.advancedOptions.maxDate ?? '2999-12-31'">
  </div>
  
  <!-- Help Message -->
  <small class="form-text text-muted" *ngIf="question?.helpMessage">
    {{ question.helpMessage }}
  </small>
</div>


<!-- <div class="invalid-feedback" *ngIf="!isValid">{{question.label}} is required</div> -->

<!-- Validation: Required Field -->
<div class="my_invalid_feedback" *ngIf="isEmpty && isTouched && question.required">{{question.label}} es un campo requerido</div>

<!-- Validation: Regex Failed -->
<div class="my_invalid_feedback" *ngIf="!isEmpty && isTouched && question.controlType =='textbox' && !isValid">
  {{questionAsTextbox.advancedOptions.msgInCaseRegexFailed}}
</div>
