<div class="containerInvoiceSearch pb-5 h-auto ">

  <div class="containerSpinner" *ngIf="showSpinnerProcess">
    <div class="spinner-grow text-primary m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </symbol>
  </svg>
  <div class="alert alert-light alert-dismissible fade show alert-dismissible-serch text-primary-emphasis" role="alert">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill" /></svg>
    ¡Recuerda! Para acceder a tu factura, por favor ingresa los datos solicitados a continuación. Mantén a la mano tu Ticket de compra.

    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div class="container pb-5 pt-5  containerContactHeader">
    <div class="containerContactHeaderText"><h3 class="text-center h2style">Consultar Factura</h3><br><p>Aquí puedes acceder fácilmente a tus detalles de facturación.</p></div>
    <div class="containerContactHeaderImg"><img title="imgen" [src]="CONF?.staticContent?.modules?.invoiceSearch?.img_header" [alt]="CONF?.staticContent?.modules?.invoiceSearch?.alt_imgHeader" class="contactHeaderImg"></div>
  </div>
  <div class="container d-flex row m-auto ">
    <div class="container d-flex row m-auto">
      <form class="row gy-4 gx-4 m-0 ">

        <div class="col-md-6 col-lg-4 col-sm-12 offset-md-3 offset-lg-4">
          <label for="exampleFormControlInput1" class="form-label">Elige la opción</label>
          <select class="form-select" aria-label=".form-select-lg example" aria-label="Disabled select example"  [(ngModel)]="selectedOption" name="tipo">
            <option value="1" selected>TICKET</option>
            <option value="2">UUID</option>
            <!-- <option value="3">RFC</option> -->
          </select>
        </div>

        <div class="container d-flex row m-auto">
          <div class="col-md-6 col-lg-4 col-sm-12 offset-md-3 offset-lg-4" *ngIf="selectedOption == 2">
            <label for="searchValue" class="form-label col">
              UUID
              <!-- <button title="mostrar" type="button" class="btn btn-primary buttonTooltip" (click)="ticketShow();"><i
                  class="bi bi-question-circle-fill"></i></button> -->
            </label>
            <input type="text" class="form-control" id="searchValue" [(ngModel)]="uuidForm" name="UUID" required />
          </div>
        </div>
        

        <div class="col-md-6 col-lg-4 col-sm-12 offset-md-3 offset-lg-4" *ngIf="selectedOption == 1">
          <label for="sucursalValue" class="form-label col">
            Sucursal
          </label>
          <input type="text" class="form-control" id="sucursalValue" [(ngModel)]="sucursalForm" name="sucursal" required />
          <label for="montoValue" class="form-label col">
            Monto
          </label>
          <input type="number" class="form-control" id="montoValue" [(ngModel)]="montoForm" name="monto" step="any" required />
          <label for="ticketValue" class="form-label col">
            TicketID
          </label>
          <input type="text" class="form-control" id="ticketValue" [(ngModel)]="ticketForm" name="ticket" required />
          <label for="canalValue" class="form-label col">
            Canal de Venta
          </label>
          <input type="text" class="form-control" id="canalValue" [(ngModel)]="canalVentaForm" name="canalVenta" required />
          <label for="fechaVentaValue" class="form-label col">
            Fecha de Venta
          </label>
          <input type="date" class="form-control" id="fechaVentaValue" [(ngModel)]="fechaVentaForm" name="fechaVenta" required />
        </div>

        <div class="col-md-6 col-lg-4 col-sm-12 offset-md-3 offset-lg-4" *ngIf="selectedOption == 3">
          <label for="fechaEmiValue" class="form-label col">
            Rfc Receptor
          </label>
          <input type="text" class="form-control" id="fechaEmiValue" [(ngModel)]="fechaEmiForm" name="fechaEmi" required />
          <label for="serieValue" class="form-label col">
            Serie
          </label>
          <input type="currency" class="form-control" id="serieValue" [(ngModel)]="serieForm" name="serie" required />
          <label for="folioValue" class="form-label col">
            Folio
          </label>
          <input type="text" class="form-control" id="folioValue" [(ngModel)]="folioForm" name="folio" required />
          <label for="fechaEmiValue" class="form-label col">
            Fecha de Emision
          </label>
          <input type="date" class="form-control" id="fechaEmiValue" [(ngModel)]="fechaEmiForm" name="fechaEmi" required />
        </div>

      </form>

      <div class="d-flex flex-column text-center pt-5" *ngIf="showSearchButton">
        <div class="alert alert-success m-0" role="alert">
          <i class="bi bi-download"></i> Aquí puede descargar su factura en formato PDF y XML.
        </div>
        <div>
          <a *ngIf="existsPdf" style="color:white;" class="btn btn-danger  col-12 col-lg-3 col-md-8 col-sm-12 mt-5 mb-5 heightButton " target="_blank" href="{{urlPdf}}"><i class="bi bi-filetype-pdf"></i> Descargar PDF</a>
        </div>
        <div>
          <a *ngIf="existsXml" style="color:white;" class="btn btn-danger col-12 col-lg-3 col-md-8 col-sm-12 mb-5 heightButton" href="{{urlXml}}" target="_blank"><i class="bi bi-filetype-xml"></i> Descargar XML</a>
        </div>
      </div>

      <div class="d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center flex-wrap-reverse  mt-5 mb-5 p-0 ">
        <button class="btn btn-secondary  col-lg-2 col-md-3 col-12  heightButton mt-lg-0 mt-md-0  mt-3 " type="reset" (click)="eraseSearch()">Borrar</button>
        <button class="btn btn-primary col-lg-2 col-md-3 col-12 ms-lg-5 ms-md-5   buttonSubmit heightButton" type="button" (click)="onListenFormValue($event)">Buscar</button>
      </div>
      <div class="alert alert-danger m-0 font-weight-bold" role="alert" *ngIf="error">
        {{message}}
      </div>
    </div>
  </div>
</div>

<!-- Modal Ejemplo Ticket-->
<div class="modal fade show modal-lg pt-5 pb-5" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
     *ngIf="showticket">
  <div class="modal-dialog modal-dialog-centered container mt-5 mb-5  " role="document">
    <div class="modal-content">
      <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </symbol>
        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </symbol>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </symbol>
      </svg>
      <div class="alert alert-primary d-flex align-items-center alertModalHome background-alert" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill" /></svg>
        <div>
          Desliza el Ticket
        </div>
      </div>
      <div class="modal-body modalHomeText containerTicket">
        <img [src]="CONF?.staticContent?.modules?.invoiceSearch?.img_ticket" class="img-fluid" [alt]="CONF?.staticContent?.modules?.invoiceSearch?.alt_imgTicket">
      </div>

      <div class="modal-footer ">
        <button type="button" class="btn btn-secondary buttonSecondary col-lg-3 me-lg-5  " data-dismiss="modal" (click)="ticketShow()">Cerrar</button>
      </div>



    </div>
  </div>
</div>


<app-action-modal 
  [isOpen]="actionModalInfo.isOpen" 
  [titleModal]="actionModalInfo.titleModal"
  [contentModal]="actionModalInfo.content" 
  [showCancelButton]="actionModalInfo.showCancelButton"
  [cancelButtonText]="actionModalInfo.cancelButtonText"
  [confirmButtonText]="actionModalInfo.confirmButtonText"
  (onClose)="actionModalInfo.onCloseEvent()"
  (onCancel)="actionModalInfo.onCancelEvent()"
  >
  {{ actionModalInfo.content}}
</app-action-modal>
