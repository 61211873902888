import { Component, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { TicketsService } from '../../services/tickets.service';
import { FormBuilder, FormsModule, FormGroup, NgForm } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppComponent } from '../../../../../app/app.component';
import { ValidateTicketComponent } from '../../Components/ValidateTicket/validate-ticket.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/env.default';
import { BaseService } from 'src/app/shared/services/base.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { Company} from 'src/interfaces/env.default.interface';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.css'],
})
export class CustomerInformationComponent {
  public CONF: any = ConfigService.CONF; // load configuration by enviroment

  public modalInfo = {
    isOpen: false,
    titleModal: '',
    imgModal: '',
    content: '',
  };
  public actionModalInfo = {
    isOpen: false,
    titleModal: '',
    content: '',
    onCloseEvent: () => {},
  };
  public paises: any = {};
  public estados: any = {};
  public regimenFiscal: any = {};
  public regimenFiscalFilter: any = {};
  public requestTicket: any = {};
  public request: any = {};
  @Input() data: any;
  public usoCfdi: string = '';
  public usosCfdi: any = {};
  public usosCfdiFilter: any = {};
  public catFormaPago: any = {};
  public showFormaDePago: boolean = false;
  public rfc: string = '';
  public ticket: string = '';
  body: string = '';
  public razon: string = '';
  public calle: string = '';
  public numExterior: string = '';
  public numInterior: string = '';
  public colonia: string = '';
  public municipio: string = '';
  public estado: string = '';
  public pais: string = '';
  //public correo: string = "";
  public regimenFiscalFinal: string = '';
  public mailConfirm: string = '';
  public finalError: string = '';
  public showError: boolean = false;
  public process: boolean = false;
  public emailRegex: string = '^[^@]+@[^@]+.[a-zA-Z]{2,}$';
  public classInp: string = '';
  //public razonSocial: any;
  public finalEmailConfirmation: string = '';
  public allErrors: Array<string> = [];
  public isDisabledRegFiscal: boolean = false;
  public isDisabledUsoCfdi: boolean = false;

  openModal(titleModal: string, imgModal: string, content: string): void {
    this.modalInfo = { isOpen: true, titleModal, imgModal, content };
    this.modalInfo = { isOpen: true, titleModal, imgModal, content };
    console.log(this.modalInfo);
  }

  closeModal(): void {
    this.modalInfo = {
      isOpen: false,
      titleModal: '',
      imgModal: '',
      content: '',
    };
  }
  @Output('on-continue') continueEvent: EventEmitter<any> = new EventEmitter();
  @Output('on-return') returnEvent: EventEmitter<any> = new EventEmitter();

  // Catalogs
  lstState: any[] = [];
  lstCountry: any[] = [];
  lstRegimenFiscal: any[] = [];

  constructor(
    private ticketsService: TicketsService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private http: HttpClient,
    private app: AppComponent,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private config: ConfigService
  ) {
    http
      .get(this.app.env.apiUrl + 'ConsultaCif/CatalogoRegimenFiscal', {
        withCredentials: true,
      })
      .subscribe(
        (result) => {
          this.regimenFiscal = result;

          this.regimenFiscalFilter = this.regimenFiscal;

          http
            .get(this.app.env.apiUrl + 'ConsultaCif/CatalogoUsoCfdi', {
              withCredentials: true,
            })
            .subscribe(
              (result) => {
                this.usosCfdi = result;

                if (environment.company == Company.Samsonite) {
                  this.usosCfdi = this.usosCfdi.filter(
                    (j: any) =>
                      j.clave == 'S01' ||
                      j.clave == 'G01' ||
                      j.clave == 'G03' ||
                      j.clave == 'I02'
                  );
                }

                this.usosCfdiFilter = this.usosCfdi;

                this.rfcChange();
                if (this.requestTicket.receptor.regimenFiscalReceptor != null) {
                  this.updateRegimen();
                }
              },
              (error) => {
                this.handleHttpError(error);
              }
            );
        },
        (error) => {
          this.handleHttpError(error);
        }
      );

      if (environment.company == Company.Samsonite) {
        this.showFormaDePago = true;
        this.getFormaPago();
      }
  }

  // -----------------------------------------------
  // Events
  // -----------------------------------------------
  ngOnInit() {
    // load configuration by enviroment
    this.config.getConfigurationAsync().subscribe((configuration) => {
      this.CONF = ConfigService.CONF;
      this.cdr.detectChanges();
    });
    this.process = false;
    this.requestTicket = this.data.requestCFD.comprobante;
    console.log(this.requestTicket);

    this.requestTicket.receptor.rfc =
      this.requestTicket.receptor.rfc?.toUpperCase();
    this.requestTicket.receptor.nombre =
      this.requestTicket.receptor.nombre?.toUpperCase();
    this.requestTicket.receptor.addendaDomicilioExpedidoEn.calle =
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.calle?.toUpperCase();
    this.requestTicket.receptor.addendaDomicilioExpedidoEn.colonia =
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.colonia?.toUpperCase();
    this.requestTicket.receptor.addendaDomicilioExpedidoEn.municipio =
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.municipio?.toUpperCase();
    this.requestTicket.receptor.addendaDomicilioExpedidoEn.estado =
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.estado?.toUpperCase();
    this.requestTicket.receptor.addendaDomicilioExpedidoEn.pais =
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.pais?.toUpperCase();
    this.requestTicket.receptor.addendaReceptor.email =
      this.requestTicket.receptor.addendaReceptor.email?.toLowerCase();

    //this.rfcChange();
  }

  rfcChange() {
    //console.log(this.requestTicket.receptor.rfc.length);
    if (this.requestTicket.receptor.rfc.length == 12) {
      this.regimenFiscalFilter = this.regimenFiscal.filter(
        (j: any) => j.moral == 'Sí'
      );
    } else if (this.requestTicket.receptor.rfc.length == 13) {
      if (
        this.requestTicket.receptor.rfc == 'XAXX010101000' ||
        this.requestTicket.receptor.rfc == 'XEXX010101000'
      ) {
        this.regimenFiscalFilter = this.regimenFiscal.filter(
          (j: any) => j.clave == '616'
        );
        this.requestTicket.receptor.regimenFiscalReceptor = '616';
        this.isDisabledRegFiscal = true;
      } else {
        this.regimenFiscalFilter = this.regimenFiscal.filter(
          (j: any) => j.fisica == 'Sí'
        );
      }
    }
  }
  usoChange() {
    console.log(this.requestTicket.receptor.usoCfdi);
  }
  updateRegimen() {
    let regimen = this.requestTicket.receptor.regimenFiscalReceptor;
    document.querySelectorAll('#inputState')[0].removeAttribute('disabled');
    if (this.requestTicket.receptor.rfc == 'XAXX010101000' || this.requestTicket.receptor.rfc == 'XEXX010101000') {
      this.usosCfdiFilter = this.usosCfdi.filter((j: any) => j.clave == 'S01');
      this.requestTicket.receptor.usoCfdi = 'S01';
      this.isDisabledUsoCfdi = true;
      return;
    } else {
      this.usosCfdiFilter = this.usosCfdi.filter((j: any) =>
        j.regimenFiscalReceptor.includes(regimen)
      );
    }
    this.requestTicket.receptor.usoCfdi = 'a';
  }
  getRequestTicket() {
    this.rfc = this.data.rfc;
    this.ticket = this.data.ticket;
    this.usoCfdi = this.data.usoCfdi;
    this.body =
      '{"numTicket": "' +
      this.ticket +
      '","rfcReceptor": "' +
      this.rfc +
      '"  }';
    //console.log(this.body);
    this.http
      .post<any>(this.app.env.apiUrl + 'ConsultaCif/RequestTicket', this.body, {
        headers: { 'Content-Type': 'application/json' },
      })
      .subscribe(
        (result) => {
          this.request = result;
          this.requestTicket = result.requestCFD.comprobante;
          if (this.requestTicket.receptor.addendaReceptor == null) {
            this.requestTicket.receptor.addendaReceptor = {
              bodegaReceptor: null,
              domicilioReceptor: null,
              eanReceptor: null,
              email: null,
              nombreComercial: null,
              regimenFiscalDesc: null,
              usoCFDIDesc: null,
            };
          }
          this.requestTicket.receptor.rfc = this.rfc;
          this.requestTicket.receptor.usoCfdi = this.usoCfdi;
          //console.log(this.requestTicket);
        },
        (error) => console.error(error)
      );
  }
  clickContinue() {
    this.process = true;
    if (this.checkFields()) {
      this.showError = false;
      /*this.requestTicket.folio = this.data.ticket;*/
      this.request.comprobante = this.requestTicket;
      this.process = false;
      this.continueEvent.emit(this.request);
    } else {
      this.process = false;
      this.showError = true;
    }
  }

  clickReturn() {
    this.returnEvent.emit();
  }

  onLoadCIF(cif: any) {
    const file: File = cif.target.files[0];

    // Validate empty file
    if (file == null) {
      this.onShowMessage('Archivo no valido');
      return;
    }

    // Validate file type
    if (file.type !== 'application/pdf') {
      this.onShowMessage('Archivo no valido');
      return;
    }

    this.ticketsService.validateCif(file).subscribe((data: any) => {
      this.process = true;
      if (data == null || data == undefined) {
        this.onShowMessage('Archivo no válido');
        this.process = false;
        return;
      }
      if (
        data.rfc?.toUpperCase() !=
        this.requestTicket.receptor.rfc?.toUpperCase()
      ) {
        this.onShowMessage(
          'El RFC de la constancia no coincide con el RFC capturado'
        );
        this.process = false;
        return;
      }
      this.process = false;
      this.requestTicket.receptor.rfc = data?.rfc;
      this.requestTicket.receptor.nombre = data?.nombre;
      this.requestTicket.receptor.residenciaFiscal = data?.residenciaFiscal;
      this.requestTicket.receptor.regimenFiscalReceptor =
        data?.regimenFiscalReceptor;
      this.updateRegimen();
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.calle =
        data?.addendaDomicilioExpedidoEn.calle;
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.noExterior =
        data?.addendaDomicilioExpedidoEn.noExterior;
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.noInterior =
        data?.addendaDomicilioExpedidoEn.noInterior;
      this.requestTicket.receptor.addendaDomicilioExpedidoEn.colonia =
        data?.addendaDomicilioExpedidoEn.colonia;
    });
  }
  checkFields() {
    let count = 0;
    let error = '';
    let regex = new RegExp('^[^@]+@[^@]+.[a-zA-Z]{2,}$');
    let rfcRegex = new RegExp(
      '[A-Z&amp;Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]'
    );
    let cpRegex = new RegExp('[0-9]{5}');
    //console.log(this.requestTicket.receptor.regimenFiscalReceptor);

    this.allErrors = new Array<string>();

    if (
      this.requestTicket.receptor.nombre == null ||
      this.requestTicket.receptor.nombre.length < 1
    ) {
      error += 'Razón social';
      this.classInp = 'is-invalid';
      this.allErrors.push('Razón social');

      count++;
    } else {
      this.classInp = 'is-valid';
    }

    if (
      this.requestTicket.receptor.regimenFiscalReceptor == null ||
      this.requestTicket.receptor.regimenFiscalReceptor.length < 2
    ) {
      this.allErrors.push('Régimen fiscal');
      count++;
    }

    if (
      this.requestTicket.receptor.usoCfdi == null ||
      this.requestTicket.receptor.usoCfdi.length < 2
    ) {
      this.allErrors.push('Uso de CfDI');
      count++;
    }

    if (
      this.requestTicket.receptor.residenciaFiscal == null ||
      !cpRegex.test(this.requestTicket.receptor.residenciaFiscal)
    ) {
      this.allErrors.push('Código postal');
      count++;
    }
    if (!regex.test(this.requestTicket.receptor.addendaReceptor.email)) {
      this.allErrors.push('Correo electrónico');
      count++;
    }

    if (
      this.mailConfirm == '' ||
      this.mailConfirm != this.requestTicket.receptor.addendaReceptor.email ||
      !regex.test(this.mailConfirm)
    ) {
      this.allErrors.push('Confirmación de correo electronico');
    }

    return this.allErrors.length == 0;
  }

  onSubmitForm() {}

  onShowMessage(msg: string) {
    this.alertService.showMessage('Advertencia', msg);
  }

  showSessionExpireModal(): void {
    this.actionModalInfo = {
      isOpen: true,
      titleModal: 'Su sesión ha expirado',
      content:
        'El tiempo de sesión ha sido excedido. Volverá a la página principal.',
      onCloseEvent: this.onCloseSession.bind(this),
    };
  }

  onCloseSession(): void {
    this.actionModalInfo = {
      isOpen: false,
      titleModal: '',
      content: '',
      onCloseEvent: () => {},
    };
    this.router.navigate(['/home']);
  }

  private handleHttpError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      // Handle HTTP errors
      if (error.status === 401) {
        // Handle 401 error by triggering a token refresh
        this.showSessionExpireModal();
      } else {
        // Handle other HTTP errors
        console.error('HTTP error:', error);
      }
    } else {
      console.error('Error:', error);
    }
  }


  async getFormaPago() {

    const urlCatalogoFormaDePago =
      this.app.env.apiUrl + 'ConsultaCif/CatalogoFormaDePago';
    try {
      const result = await firstValueFrom(
        this.http.get(urlCatalogoFormaDePago)
      );
      this.catFormaPago = result;
      //this.mapeaCatFormaPago();
    } catch (error) {
      console.error(error);
    } finally {
      this.cdr.detectChanges();
    }
  }

  // mapeaCatFormaPago() {
  //   const arrayOptions = this.getArrayCatFormaPago();
  //   if (environment.company == Company.Samsonite) {
  //     this.CONF.dynamicForms.validateTicketForm[6].advancedOptions = {
  //       options: arrayOptions,
  //     };
  //   }
  // }

  // getArrayCatFormaPago() {
  //   const arreglo: any[] = [];
  //   this.catFormaPago.forEach(
  //     (formaPago: { text: string; value: string }) => {
  //       const leyenda = formaPago.text;
  //       arreglo.push({ key: formaPago.value, value: leyenda });
  //     }
  //   );
  //   return arreglo;
  // }


}
