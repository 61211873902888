import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment } from 'src/environments/env.default';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent {
  public CONF: any = ConfigService.CONF;// load configuration by enviroment

  constructor(private cdr: ChangeDetectorRef, private config: ConfigService){

  }

  ngOnInit() {
    this.config.getConfigurationAsync().subscribe((configuration) => { this.CONF = ConfigService.CONF; this.cdr.detectChanges();});
  }

}
