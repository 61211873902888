<div class="bg-dark text-white d-flex align-items-center justify-content-center col-md-12 border-top border-top-1 border-secondary container-footer">
  <footer class="d-flex  align-items-center py-3 my-4 ">
    <div class="col-md-4 d-flex align-items-center flex-column col-md-12 text-white">
      <span class=" text-secondary">Copyright © 2023 Designed by Masteredi</span>
      <p class="text-secondary">ESTE PORTAL EMITE CFDI VERSION 4.0</p>
      <p><a href="http://www.masteredi.mx/aviso-de-privacidad" target="_blank" class="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Aviso de privacidad.</a></p>
    </div>
  </footer>
</div>


<!--

<div class="container-fluid bg-dark d-flex justify-content-center border-top">
  <footer class="container row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 ">
    <div class="col-12 col-lg-12 mb-3 text-center">
      <p class=" text-white-50">ESTE PORTAL EMITE CFDI VERSION 4.0</p>
      <p class="text-white-50  ">Copyright © 2023 Designed by Masteredi</p>
      <img src="assets/images/imgMateredi.png" class="imgFooter " alt="Imagen Logo de Materedi">
    </div>

    <div class="col-lg-6 mb-3 text-white-50  justify-content-center">
      <h5>Conócenos</h5>
      <ul class="nav flex-column text-secondary">
        <li class="nav-item "><a href="http://www.masteredi.mx/" class="nav-link p-0 text-white-50" target="_blank">Inicio</a></li>
        <li class="nav-item "><a href="http://www.masteredi.mx/quienes-somos/" class="nav-link p-0 text-white-50 " target="_blank">Nosotros</a></li>
        <li class="nav-item "><a href="http://www.masteredi.mx/socios-comerciales/" class="nav-link p-0 text-white-50" target="_blank">Socios Comerciales</a></li>
        <li class="nav-item "><a href="http://www.masteredi.mx/aviso-de-privacidad" target="_blank" class="nav-link p-0 text-white-50">Aviso de privacidad</a></li>
      </ul>
    </div>

    <div class="col-lg-6 mb-3 text-white-50  justify-content-center">
      <h5>Redes Sociales</h5>
      <ul class="nav flex-col">
        <li class="nav-item mb-1 p-2"><a href="https://www.facebook.com/Masteredi.mx" class="nav-link p-0 text-white-50" target="_blank"><i class="bi bi-facebook"></i></a></li>
        <li class="nav-item mb-1 p-2"><a href="https://twitter.com/masteredi_mx" class="nav-link p-0 text-white-50" target="_blank"><i class="bi bi-twitter"></i></a></li>
        <li class="nav-item mb-1 p-2"><a href="https://www.linkedin.com/company-beta/517365/" class="nav-link p-0 text-white-50" target="_blank"><i class="bi bi-linkedin"></i></a></li>
      </ul>
    </div>

  </footer>
</div>

  -->
