<div class="containerHome container-fluid">
  <div class="textMainHome">
    <h1 class="display-5 text-white">Bienvenidos al Portal de Facturación.</h1>
    <p class="h5 text-white ">Tiene como máximo 60 días naturales posteriores a la compra para realizar su factura.</p>
    <br>
    <div class="container col-12  ">
      <a class="btn btn-danger" href="creafactura" role="button">Generar Factura</a>
    </div>
    <!--<p class="display-6 text-dark-emphasis">Tiene como maximo 30 dias para realizar su factura</p>-->

  </div>
</div>



<div class="alert mt-5 mb-5 d-flex align-items-center container bg-primary-subtle" role="alert">
  <div>
    Restricciones de Facturación:<br />
    La factura electrónica imprime únicamente los artículos facturables de acuerdo a cada compra y en forma total.<br />
    <div [ngClass]="{'collapse': !isContentVisible}">
      <div class="card-body">
        La facturación electrónica se puede realizar en nuestro portal dentro de los 60 días naturales posteriores a la compra, posterior a esta fecha no es permisible realizar la facturación.<br>
        El sistema de facturación electrónica no permite la facturación parcial, pago de servicio o donaciones.<br>
        En caso de requerir cancelación de facturas o correcciones, se pueden realizar únicamente en la sucursal de compra y dentro de los 60 días naturales posteriores a la compra.<br>
        Para Facturación en Sucursal, puedes emitir las facturas de tus compras únicamente en la sucursal donde se efectuó la compra.<br>
      </div>
    </div>
    <a class="btn btn-primary buttonSubmit mt-2" (click)="toggleContentVisibility()" role="button">
      {{ isContentVisible ? 'Ver menos' : 'Ver más' }}
    </a>
  </div>
</div>


<!--
<div class="container ">
  <div class="row g-0 bg-light position-relative mt-5 mb-5 container  ">
    <div class="col-md-4 mb-md-0 p-md-4">
      <img src="assets/images/img2.jpg" class="w-100" alt="...">
    </div>
    <div class="col-md-6 p-4 ps-md-0">
      <h5 class="mt-0">Te informamos </h5>
      <p>
        Derivado de la Reforma Fiscal emitida por el Servicio de Administración Tributaria (SAT) y publicada en el Diario Oficial de la Federación,
        a partir del 1 de enero del 2022 se actualizará la versión de los Comprobantes Fiscales Digitales por Internet (CFDI), siendo relevante que
        los siguientes datos coincidan con los registrados en tu Constancia de Situación Fiscal:<br>
        1- Registro Federal de Contribuyentes con homoclave (RFC a 13 posiciones Persona Física o 12 Persona Moral)<br>
        2- Nombre completo (nombre(s), apellido paterno, apellido materno, en caso de ser persona moral no incluir las siglas que aparecen al final del
        nombre de tu empresa ejem. SA de CV, S L DE RL, C. A etc..)<br>
        3- Dirección completa (calle, no. interior y/o exterior, colonia, alcaldía, código postal)<br>
        4- Régimen fiscal inscrito en tu Constancia de Situación Fiscal.<br>
        Los datos del domicilio fiscal los podrás obtener de tu Constancia de Situación Fiscal, la cual puedes consultar en el portal del SAT en:
      </p>
      <a href="https://www.sat.gob.mx/home" class="stretched-link">Portal de trámites y servicios – SAT</a>
    </div>
  </div>

</div>
-->
<!----Modal---->
<!-- Modal -->
<!---->

<div class="modal fade show modal-lg" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" *ngIf="mostrarModal">
  <div class="modal-dialog modal-dialog-centered  " role="document">
    <div class="modal-content modalStart">
      <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </symbol>
        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </symbol>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </symbol>
      </svg>
      <div class="alert alert-primary d-flex align-items-center alertModalHome background-alert" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill" /></svg>
        <div>
          Nuevo CFDI
        </div>
      </div>
      <div class="modal-body overflow-auto ">
        <br>
        Te informamos que derivado de la Reforma Fiscal emitida por el Servicio de Administración Tributaria (SAT) y publicada en el Diario Oficial de la Federación, a partir del 1 de enero del 2022 se actualizará la versión de los Comprobantes Fiscales Digitales por Internet (CFDI), siendo relevante que los siguientes datos coincidan con los registrados en tu Constancia de Situación Fiscal:<br>
        <i class="bi bi-1-circle-fill iconNumberHome"></i> Registro Federal de Contribuyentes con homoclave (RFC a 13 posiciones Persona Física o 12 Persona Moral)<br>
        <i class="bi bi-2-circle-fill"></i> Nombre completo (nombre(s), apellido paterno, apellido materno, en caso de ser persona moral no incluir las siglas que aparecen al final del nombre de tu empresa ejem. SA de CV, S L DE RL, C. A etc..)<br>
        <i class="bi bi-3-circle-fill"></i> Dirección completa (calle, no. interior y/o exterior, colonia, alcaldía, código postal)<br>
        <i class="bi bi-4-circle-fill"></i> Régimen fiscal inscrito en tu Constancia de Situación Fiscal.<br>
        Los datos del domicilio fiscal los podrás obtener de tu Constancia de Situación Fiscal, la cual puedes consultar en el portal del SAT
      </div>
      <form>
        <div class="form-check ms-5">
          <input class="form-check-input" type="checkbox" [(ngModel)]="isChecked" id="flexCheckDefault" name="nombre2">
          <label class="form-check-label" for="flexCheckDefault">
            Acepto haber leído.
          </label>
        </div>
        <div class="modal-footer ">
          <button type="button" class="btn btn-secondary buttonSecondary col-12 col-lg-3 col-md-3 " data-dismiss="modal" (click)="hiddenModal()" [disabled]="!isChecked">Aceptar</button>
        </div>
      </form>


    </div>
  </div>
</div>


