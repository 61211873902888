import { Component, Input, Output } from '@angular/core';
import { IModalData, AlertService, AlertType } from '../../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {
  title!: string;
  message!: string;
  showButtons: boolean = false;
  isOpenModal:boolean = false;
  alertType: AlertType = AlertType.message; // Por default el tipo de alerta basico

  constructor(private modalService: AlertService) { }

  ngOnInit() {
    this.modalService.getModalObservable().subscribe((modalData: IModalData | null) => {
      // Show Modal
      if(modalData != null) {
        this.title = modalData.title;
        this.message = modalData.message;
        this.showButtons = modalData?.showButtons ?? false;
        this.alertType = modalData?.alertType ?? AlertType.message;
        this.isOpenModal = true;
        return;
      }

      // Hide Modal
      this.title = "";
      this.message = "";
      this.showButtons = false;
      this.isOpenModal = false;
      this.alertType = AlertType.message;
    });
  }

  onClose(){
    this.modalService.closeModal();
  }
  onAccept(){    
  }
  

}