import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  private baseResource = "ConsultaCif";

  constructor(private baseService: BaseService) { }

  validateCif(file: File): Observable<any>{
    const formData = new FormData();
    formData.append('file', file);
    return this.baseService.post<any>(this.baseResource, formData);
  }

  loadCatalogs(): Observable<any>{
    return this.baseService.get<any>(this.baseResource + "/CatalogoUsoCfdi");
  }
}
