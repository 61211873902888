import { Component } from '@angular/core';
import { environment } from '../environments/env.default';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Portal Público';
  env = environment;
}



