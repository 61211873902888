import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, Subject, map, of } from 'rxjs';
import { QuestionBase } from 'src/app/shared/classes/question-base';
import { CheckboxQuestion} from 'src/app/shared/classes/question-checkbox';
import { DateQuestion } from 'src/app/shared/classes/question-date';
import { DropdownQuestion, IDropdown } from 'src/app/shared/classes/question-dropdown';
import { RadioQuestion } from 'src/app/shared/classes/question-radio';
import { TextboxQuestion } from 'src/app/shared/classes/question-textbox';
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment } from 'src/environments/env.default';
import { TypePropertyInput } from 'src/interfaces/env.default.interface';
import { ICheckbox, IDate, IRadio, ITextbox } from 'src/interfaces/questions.interface';

@Injectable()
export class QuestionService {
  // Chanel for update the status validations
  private valdations = new BehaviorSubject<any>(null);
  responseValidation$ = this.valdations.asObservable();
  public CONF: any = ConfigService.CONF;// load configuration by enviroment

  // Chanel to call Callback Methods
  private eventsForms = new Subject<string>;
  events$ = this.eventsForms.asObservable();

  public form!: FormGroup;

  constructor(private config: ConfigService) {
  }

  updateResponseValidation(newValidation: any) {
    this.valdations.next(newValidation);
  }

  sendEventName(newEvent: string) {
    this.eventsForms.next(newEvent);
  }

  // Load questions to be userd in the ValidateTicketComponent
  getQuestions_ValidateTicketForm() {

    // CASE: Conf is not loaded yet
    if(this.CONF == null){
      return this.config.getConfigurationAsync(). // wait to load configuration 
      pipe( map( (configuration)=> {return this.loadOrder()} )); // return the questions
    }
    // CASE: Conf is loaded
    else{
      return of(this.loadOrder()) // return the questions
    }
    
  }


  loadOrder(){
    if(this.CONF == null){
      this.CONF = ConfigService.CONF;
    }

    let questions: QuestionBase<any>[]  = [];
    this.CONF.dynamicForms.validateTicketForm.forEach((input: any) => {
      if(input.typeQuestion == TypePropertyInput.textbox)
        questions.push(new TextboxQuestion(input as  QuestionBase<ITextbox>));

      else if(input.typeQuestion == TypePropertyInput.dropdown)
        questions.push(new DropdownQuestion(input as QuestionBase<IDropdown>));
      
      else if(input.typeQuestion == TypePropertyInput.checkbox)
        questions.push(new CheckboxQuestion(input as QuestionBase<ICheckbox>));
      
      else if(input.typeQuestion == TypePropertyInput.radio)
        questions.push(new RadioQuestion(input as QuestionBase<IRadio>));
      
      else if(input.typeQuestion == TypePropertyInput.date)
        questions.push(new DateQuestion(input as QuestionBase<IDate>))

    });

    return questions.sort((a, b) => a.order - b.order);
  }


}