import { Component, Input } from '@angular/core';
import {  QuestionBase } from '../../classes/question-base';
import { FormGroup } from '@angular/forms';
import { QuestionService } from 'src/app/modules/Invoices/services/question.service';
import { CheckboxQuestion } from '../../classes/question-checkbox';
import { DropdownQuestion } from '../../classes/question-dropdown';
import { TextboxQuestion } from '../../classes/question-textbox';
import { RadioQuestion } from '../../classes/question-radio';
import { DateQuestion } from '../../classes/question-date';
import { TypePropertyInput } from 'src/interfaces/env.default.interface';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
})
export class InputsFormulariosComponent<T> {
  @Input() question!: QuestionBase<T>;
  @Input() form!: FormGroup;
  get isEmpty() {
    return (
      this.questionService.form.controls[this.question.key].value === null ||
      this.questionService.form.controls[this.question.key].value ===
        undefined ||
      this.questionService.form.controls[this.question.key].value === ''
    );
  }

  get isTouched() {
    return this.questionService.form.controls[this.question.key].touched;
  }

  get isValid() {
    return this.questionService.form.controls[this.question.key].valid;
  }

  constructor(private questionService: QuestionService) {}

  onEventTooltipHelp(eventName: string = '') {
    console.log('onEventTooltipHelp', eventName);
    if (eventName !== '' && eventName !== null && eventName !== undefined) {
      this.questionService.sendEventName(eventName);
    }
  }

  onInputChange($event: string = '') {
    // Case: The parent component wants to know when the input changes
    this.questionService.sendEventName($event);

    // Case: In the textbox, when we apply a format in the string,
    if (this.question.controlType == 'textbox') {
      // Apply numeric format
      if (
        this.questionAsTextbox?.advancedOptions?.optLocaleString?.style ==
          'decimal' ||
        this.questionAsTextbox?.advancedOptions?.optLocaleString?.style ==
          'currency'
      ) {
        // Load number
        let numero = parseFloat(
          this.form.controls[this.question.key].value.replace(/[^\d.-]/g, '')
        );

        if (isNaN(numero)) {
          numero = 0;
        }

        // Apply Format
        this.form.controls[this.question.key].setValue(
          numero.toLocaleString(
            this.questionAsTextbox?.advancedOptions?.locale,
            this.questionAsTextbox.advancedOptions.optLocaleString
          )
        );
      }
    }
  }

  onInputKeyUp($event: string = '') {
    // Case: The parent component wants to know when the input changes
    this.questionService.sendEventName($event);

    // Case: In the textbox, when we apply a format in the string,
    if (this.question.controlType == 'textbox') {
      //Apply ToUpperString
      if (this.questionAsTextbox?.advancedOptions?.toUpperCase === true) {
        // Load text
        let text = this.form.controls[this.question.key].value.toUpperCase();
        this.form.controls[this.question.key].setValue(text);
      }
    }
  }

  onInputKeyDown(event: KeyboardEvent): void {

    // Case: In the textbox, when we apply a format in the string,
    if (this.question.controlType == 'textbox') {
      if (
        this.questionAsTextbox?.advancedOptions?.optLocaleString?.style ==
          'currency'
      ) {
        const regex = /[^\d.-]/g;

        // Allow special key
        if (
          event.key === 'Backspace' ||
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight' ||
          event.key === 'Tab' ||
          event.key === 'Enter'
        ) {
          return;
        }

        // If the key dont match the regex, prevent the input
        if (regex.test(event.key)) {
          event.preventDefault();
        }
      }
    }
  }

  onRadioChange(key: string, value: string = '') {
    this.form.controls[key].setValue(value);
  }

  get questionAsCheckbox() {
    return this.question as unknown as CheckboxQuestion;
  }

  get questionAsDropdown() {
    return this.question as unknown as DropdownQuestion;
  }

  get questionAsTextbox() {
    return this.question as unknown as TextboxQuestion;
  }

  get questionAsRadio() {
    return this.question as unknown as RadioQuestion;
  }

  get questionAsDate() {
    return this.question as unknown as DateQuestion;
  }
}
