<!--
<nav class="containerNav">
  <div class="nav_hidden" id="nav_hidden">
      <i class="fas fa-times fa-times_nav" id="close_nav"></i>
  </div>
  <div class="bars">
      <i class="fas fa-bars" id="fa-bars"></i>
  </div>
  <div class="links " id="links">
    <a class="links_a" id="links_a" [routerLink]="'home'" >Inicio</a>
    <a class="links_a" id="links_a" [routerLink]="'creafactura'" >Generar factura</a>
      <a class="links_a" id="links_a2" [routerLink]="'buscarfactura'" >Consultar factura</a>
      <a class="links_a" id="links_a2" [routerLink]="'faq'" >Preguntas Frecuentes</a>
      <a class="links_a" id="links_a3" [routerLink]="'contacto'" >Enviar correo</a>
  </div>
  <div class="containerNavLogo">
      <img src="../assets/images/logo.png" alt="Logo">
  </div>
  <div class="linksFreeShowMain" id="linksFreeShow">

  </div>
</nav>
-->
<!---->
<nav class="navbar navbar-expand-lg {{darkModeEnabled===false ? 'bg-white': 'bg-dark-subtle' }}  sticky-top navStyle navStyle p-1 ">
  <div class="container">
    <div>
      <a class="navbar-brand " [routerLink]="'home'" *ngIf="darkModeEnabled; else notLogo">
        <img [src]="CONF?.staticContent?.shared?.components?.navbar?.img_logo_blackMode" alt="Img Logo"  class="imgLogo">
      </a>
      <ng-template #notLogo >
        <img [src]="CONF?.staticContent?.shared?.components?.navbar?.img_logo_default" alt="Img Logo"  class="imgLogo">
      </ng-template>
    </div>

    <button class="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="bi {{ icon }} "></i>
    </button>
    <div class=" {{UlStatus==false?'boxNavFree':'deleteboxNavFree'}}" (click)="toggleMenu()"></div>
    <div class="collapse navbar-collapse justify-content-end {{UlStatus==false?'show':''}}"  >
      <ul class="navbar-nav {{darkModeEnabled===false ? 'bg-white': 'bg-dark-subtle' }} ">
        <li class="nav-item " (click)="toggleMenu()"   >
          <a class="nav-link  p-0  ps-3 pe-3  " aria-current="page" [routerLink]="'home'" routerLinkActive="active">Inicio</a>
        </li>
        <li class="nav-item" (click)="toggleMenu()"  >
          <a class="nav-link p-0 ps-3 pe-3 " [routerLink]="'creafactura'" routerLinkActive="active">Generar Factura</a>
        </li>
        <li class="nav-item" (click)="toggleMenu()"  >
          <a class="nav-link p-0 ps-3 pe-3 " [routerLink]="'buscarfactura'" routerLinkActive="active">Consultar Factura</a>
        </li>
        <!-- <li class="nav-item" (click)="toggleMenu()"  >
          <a class="nav-link p-0 ps-3 pe-3 " [routerLink]="'faq'" routerLinkActive="active">Preguntas Frecuentes</a>
        </li> -->
        <li class="nav-item" (click)="toggleMenu()"  >
          <a class="nav-link p-0 ps-3 pe-3 " [routerLink]="'contacto'"routerLinkActive="active">Contáctanos</a>
        </li>
        <li class="nav-item"  >
          <div class="ms-5 ">
            <button (click)="toggleDarkMode()" *ngIf="darkModeEnabled; else notIconDetails" class="buttonTheme"><i class="bi bi-brightness-high-fill"></i></button>
          </div>
          <ng-template #notIconDetails >
            <button (click)="toggleDarkMode()" class="buttonTheme"><i class="bi bi-moon-stars-fill"></i></button>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>

</nav>

<!--
<nav class="containerNav container">
  <div class="containerNavLogo">
    <img src="../assets/images/logo.png" alt="Logo">
</div>
  <div class="nav_hidden" id="nav_hidden">
      <i class="fas fa-times fa-times_nav" id="close_nav"></i>
  </div>
  <div class="bars">
      <i class="fas fa-bars" id="fa-bars"></i>
  </div>
  <div class="links " id="links">
   <ul class="linksUl">
    <li class="linksLi"><a class="links_a" id="links_a" [routerLink]="'home'" >Inicio</a></li>
    <li class="linksLi"><a  id="links_a" [routerLink]="'creafactura'" >Generar factura</a></li>
    <li class="linksLi"><a id="links_a2" [routerLink]="'buscarfactura'" >Consultar factura</a></li>
    <li class="linksLi"><a id="links_a3" [routerLink]="'contacto'" >Enviar correo</a></li>
   </ul>
  </div>


</nav>
-->
