<div class="containerInvoiceOne">
  <!--
  <div id="FullSpinner" *ngIf="process">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
  -->
  <div class="containerSpinner" *ngIf="showSpinnerProcess">
    <div class="spinner-grow text-primary m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </symbol>
  </svg>
  <div class="alert alert-light alert-dismissible fade show alert-dismissible-serch text-primary-emphasis " role="alert">
    <svg class="bi flex-shrink-0 me-2 " width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill" /></svg>
    ¡Recuerda! Para generar tu factura, por favor ingresa los datos solicitados a continuación. Mantén a la mano tu Ticket de compra.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div class=" container-fluid HeaderInvoiceOne mt-lg-2 bg-primary-subtle bg-opacity-10">
    <div class="containerHeaderInvoiceOneT">
      <h3 class="text-center h2style pb-lg-4 pb-md-4 pb-3 ">Ingreso de Datos.</h3>
      <p>Tiene como máximo 60 días naturales posteriores a la compra para realizar su factura.</p>
      <button type="button" class="btn btn-secondary buttonSecondary" data-dismiss="modal" (click)="onModalTicket()">Ejemplo ticket.</button>
    </div>
    <div class="containerHeaderInvoiceOneImg">
      <img [src]="CONF?.staticContent?.modules?.invoices?.components?.validateTicket?.img_header" class="img-fluid" alt="...">
    </div>
  </div>

  <div class="container d-flex row m-auto ">

    <form class="row gy-4 gx-4 m-0">      
      <ng-container *ngIf="questions$ | async as questions">
        <app-dynamic-form [questions]="questions" (formValue)="onListenFormValue($event)" ></app-dynamic-form>
      </ng-container>
    </form>

  </div>

  <!-- <button (click)="testModal()">Mostrat modal</button> -->

</div>



<!-- Modal Ejemplo Ticket-->
<app-modal
 [isOpen] = "showModalTicket"
 [title] = "'Desliza el Ticket'"
 [imgBody] = "CONF?.staticContent?.modules?.invoices?.components?.validateTicket?.img_ticket"
 (onClose)="onModalTicket()">
</app-modal>

<!-- Modal Ejemplo Rfc-->
<app-modal
 [isOpen] = "showModalRFC"
 [title] = "'Ejemplo RFC'"
 [imgBody] = "CONF?.staticContent?.modules?.invoices?.components?.validateTicket?.img_RFC"
 (onClose)="onModalRFC()">
</app-modal>

<!--Modal Ejemplo Uso de CFDI-->
<app-modal
 [isOpen] = "showModalCfdi"
 [title] = "'Uso de CFDI'"
 [txtBody] = "'Seleccione el uso del CFDI permitido acorde al Régimen Fiscal. Si tienes dudas sobre la versión 4.0 de factura electrónica puedes revisar el Catálogo CFDI 4.0 del SAT aquí: Catálogo CFDI 4.0'"
 (onClose)="onModalCfdi()">
</app-modal>

<app-action-modal 
  [isOpen]="actionModalInfo.isOpen" 
  [titleModal]="actionModalInfo.titleModal"
  [contentModal]="actionModalInfo.content" 
  [showCancelButton]="actionModalInfo.showCancelButton"
  [cancelButtonText]="actionModalInfo.cancelButtonText"
  [confirmButtonText]="actionModalInfo.confirmButtonText"
  (onClose)="actionModalInfo.onCloseEvent()"
  (onCancel)="actionModalInfo.onCancelEvent()"
  >
  {{ actionModalInfo.content}}
</app-action-modal>