<div class="containerInvoiceThree">
  <!--
      <div id="FullSpinner" *ngIf="process">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
   -->
  <div class="containerSpinner" *ngIf="process">
    <div class="spinner-grow text-primary m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </symbol>
  </svg>
  <div class="alert  alert-dismissible fade show alert-dismissible-serch alert-light  text-primary-emphasis" role="alert">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill" /></svg>
    Recuerda! Para generar tu factura, por favor ingresa los datos solicitados a continuación.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div class=" container-fluid HeaderInvoiceOne mt-lg-2 bg-primary-subtle bg-opacity-10">
    <div class="containerHeaderInvoiceOneT">
      <!--
      <h3 class="text-center h2style pb-3">Crear Factura.</h3>
      <p *ngIf="!generateInvoice" class="text-center fw-bold">
        RFC del emisor: {{rfc}}
        <br />
        ITU: {{itu}}
        <br />
        Totales del Ticket:
      </p>
      -->
      <h3 class="text-center h2style pb-3">Crear Factura.</h3>
      <p *ngIf="!generateInvoice" class="text-center">
        RFC del receptor:<span style="font-weight: bold;" class="fs-6"> {{rfc}}</span>
        <br />
        Ticket: <span style="font-weight: bold;" class="fs-6">{{itu}}</span>
        <!-- <br />
        Totales del Ticket: -->
      </p>
      <p *ngIf="generateInvoice" class="text-center">Listo!. Puede descargar su factura.</p>
    </div>
    <div class="containerHeaderInvoiceOneImg">
      <img [src]="CONF?.staticContent?.modules?.invoices?.components?.confirmInvoice?.img_header" class="img-fluid" [alt]="CONF?.staticContent?.modules?.invoices?.components?.confirmInvoice?.alt_imgHeader" title="img">
    </div>
  </div>

  <div class="container d-flex row m-auto ">
    <div class="row gy-4 gx-4 m-0">
      <!--Tabla 1-->
      <table class="table table-striped ">
        <thead class="table-dark bg-primary text-white">
          <tr>
            <th scope="col" class="text-center">Concepto</th>
            <th scope="col" class="text-center">Importe</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" class="text-center">Subtotal</td>
            <td class="text-center">{{ticket.subTotal | currency}}</td>
          </tr>
          <tr>
            <td scope="row" class="text-center">IVA</td>
            <td class="text-center">{{ivaTotal | currency}}</td>
          </tr>
          <tr>
            <td scope="row" class="text-center">IEPS</td>
            <td class="text-center">{{iepsTotal | currency}}</td>
          </tr>
          <tr>
            <td scope="row" class="text-center">Descuento</td>
            <td class="text-center">{{ticket.descuento | currency}}</td>
          </tr>
          <tr class="table-primary">
            <th scope="row" class="text-center font-weight-bold fs-5">Total</th>
            <th class="text-center font-weight-bold fs-5">{{ticket.total | currency}}</th>
          </tr>
        </tbody>
      </table>
      <!--Boton de Mostar Detalles-->
      <div class="d-flex  justify-content-end mt-3 mb-md-5 mb-sm-3 mb-5">
        <button type="button" class="btn btn-outline-secondary" (click)="(changeShowStatus())" *ngIf="showStatus; else notIconDetails"> <i class="bi bi-eye-fill"></i> Mostar Detalles</button>
      </div>
      <ng-template #notIconDetails>
        <button type="button" class="btn btn-outline-secondary" (click)="(changeShowStatus())"> <i class="bi bi-eye-slash-fill"></i> No Mostar Detalles</button>
      </ng-template>
      <!--Tabla 2-->
      <div class="table-responsive row m-auto " *ngIf="!showStatus">
        <table class="table table-striped ">
          <thead class="table-dark">
            <tr>
              <th scope="col" class="text-center col-3 text-center">Descripción</th>
              <th scope="col" class="text-center col-1 text-center ">Cantidad</th>
              <th scope="col" class="text-center col-1 text-center">P Unitario</th>
              <th scope="col" class="text-center col-1 text-center">Importe</th>
              <!--<th scope="col" class="text-center col-1 text-center">Porc IVA</th>-->
              <th scope="col" class="text-center col-1 text-center">Imp IVA</th>
              <th scope="col" class="text-center col-1 text-center">Imp IEPS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of conceptos;">
              <td scope="row">{{item.descripcion}}</td>
              <td class="text-center">{{item.cantidad}}</td>
              <td class="text-center">{{item.valorUnitario | currency}}</td>
              <td class="text-center">{{item.importe | currency}}</td>
              <!--<td class="text-center"></td>-->
              <td class="text-center">{{item.impIva | currency}}</td>
              <td class="text-center">{{item.impIeps | currency}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--
        <div class="d-flex flex-column pb-lg-5 rounded-2 mt-lg-5" >
          <div class="alert alert-light m-0" role="alert">
             <i class="bi bi-download"></i> Aquí puede descargar su factura en formato PDF y XML.
          </div>
          <div class="d-flex flex-lg-row flex-md-row  flex-sm-column flex-wrap  bg-light-subtle  p-5 ">
            <div class="w-50 d-flex justify-content-center  ">
              <button class="btn btn-danger col-lg-5 col-sm-5 col-12   " type="submit"><i class="bi bi-filetype-pdf"></i> Descargar PDF</button>
            </div>
            <div class="w-50 d-flex justify-content-center">
              <button class="btn btn-danger col-lg-5 col-sm-5 col-12 " type="submit"><i class="bi bi-filetype-xml"></i> Descargar XML</button>
            </div>
          </div>
        </div>
      -->

      <div class="d-flex flex-column text-center" *ngIf="generateInvoice">
        <div class="alert alert-success m-0" role="alert">
          <i class="bi bi-download"></i> Aquí puede descargar su factura en formato PDF y XML.
        </div>
        <div>
          <a *ngIf="existsPdf" class="btn btn-danger  col-12 col-lg-3 col-md-8 col-sm-12 mt-5 mb-5 heightButton " style="color:white;" href="{{urls.urlPdf}}" target="_blank"><i class="bi bi-filetype-pdf"></i> Descargar PDF</a>
        </div>
        <div>
          <a *ngIf="existsXml" class="btn btn-danger col-12 col-lg-3 col-md-8 col-sm-12 mb-5 heightButton" style="color:white;" href="{{urls.urlXml}}" target="_blank"><i class="bi bi-filetype-xml"></i> Descargar XML</a>
        </div>
      </div>

      <div class="alert alert-danger m-0 font-weight-bold" role="alert" *ngIf="error">
        {{message}}
      </div>

      <div class="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center flex-wrap-reverse  mt-5 mb-5 p-0 ">
        <button *ngIf="!generateInvoice" class="btn btn-secondary  col-lg-2 col-md-3 col-12  heightButton mt-lg-0 mt-md-0  mt-3 " type="button" (click)="clickReturn()">Regresar</button>
        <button class="btn btn-primary col-lg-2 col-md-3 col-12 ms-lg-5 ms-md-5   buttonSubmit heightButton" type="button" id="GenerarFactura" (click)="changeGenerateInvoice()" *ngIf="!generateInvoice">Generar Factura</button>
      </div>
    </div>

  </div>
</div>


<app-action-modal [isOpen]="actionModalInfo.isOpen"
                  [titleModal]="actionModalInfo.titleModal"
                  [contentModal]="actionModalInfo.content"
                  (onClose)="actionModalInfo.onCloseEvent()">{{ actionModalInfo.content }}</app-action-modal>


<!--
  <button class="buttonStyle" (click)="clickReturn()">Regresar</button>
            <button class="buttonStyle">Cancelar</button>
            <button class="buttonStyle buttonStyleAccept"  >Crear Factura</button>
-->
