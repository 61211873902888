<div class="col pt-5 ">
    <h3 class="text-center h2style pb-2">Preguntas Frecuentes.</h3>
  </div>
  <div class="accordion accordion-flush container " id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
          ¿Cómo puedo obtener mi factura electrónica?
        </button>
      </h2>
      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          Para obtener tu factura electrónica es necesario que cuentes con los siguientes datos:<br>
          RFC.<br>
          Nombre o Razón Social.<br>
          Domicilio Fiscal.<br>
          ITU (se encuentra impreso en la parte inferior del ticket de compra, si es alfanumérico es importante ingresar los guiones).<br>
          Régimen Fiscal activo en el SAT.<br>
          Uso del CFDI permitido de acuerdo al Régimen Fiscal.<br>

          La factura electrónica sólo se puede obtener 30 días naturales posteriores a tu compra, al pasar estos 30 días no nos será posible ayudarte a obtener la factura.<br>

          Si cuentas con los datos mencionados y estas dentro de los 30 días posteriores a tu compra  sigue estos pasos. <a href="#ComoGeneraFactura">Como Generar tu Factura</a>

          </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
          Los datos de mi factura son incorrectos ¿Cómo puedo cancelar o corregir la factura?
        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          Si existe algún error en los datos de la factura ya emitida,  es necesario cancelarla y volver a emitirla en nuestro punto de venta; por lo que te invitamos a que acudas a la sucursal donde realizaste la compra y solicitar la corrección o cancelación de tu factura. <br>
          Nota: Los cambios o cancelaciones SOLO pueden realizarse en la sucursal donde se hizo la compra y tienes solamente 30 días naturales  posteriores a la misma para solicitar la corrección o cancelación de tu factura.

        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
          Tengo problemas para emitir mi factura electrónica.
        </button>
      </h2>
      <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">De antemano te ofrecemos una disculpa por las molestias causadas. Si nuestro portal de facturación está presentando intermitencias, te invitamos a generar tu factura un poco más tarde. O bien, da clic en la opción Contáctanos que se encuentra en el menú de la parte superior, para que un asesor te brinde apoyo.  Es importante que cuentes con los siguientes datos: Razón Social,  Domicilio Fiscal, ITU, Imagen del ticket a facturar, Uso del CFDI y Correo. </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingFour">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
          El portal de facturación no acepta el ITU.
        </button>
      </h2>
      <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">Si el ITU de tu ticket de compra es alfanumérico, corrobora que también se estén ingresando los guiones.</div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingFive">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
          Si perdí mi ticket de compra ¿puedo facturar?
        </button>
      </h2>
      <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">Para que obtengas tu factura es necesario tengas el ITU que viene en tu ticket, si por algún motivo no lo tienes siempre puedes pedir una reimpresión del mismo, esto SOLO se puede solicitar en la sucursal donde realizaste tu compra.</div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingSix">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
          Ya pasaron los 30 días naturales para facturar ¿Qué puedo hacer?
        </button>
      </h2>
      <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">En el ticket de compra se establece un periodo de 30 días naturales para la emisión de la factura electrónica en caso no facturar dentro del periodo establecido no será posible realizar la emisión de su factura electrónica sin excepción alguna.</div>
      </div>
    </div>

</div>

<div class=" containerHomeS2 mt-5 " id="ComoGeneraFactura">
    <div class="textMainHome2">
      <h2>Como Generar tu Factura</h2>
      <p>De manera rápida y fácil.</p>
  </div>
  </div>

  <div class="container containerBoxCard mt-5 ">
    <div class="containerCard">
      <div class="card">
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manualPage_1" class="card-img-top" alt="...">
        <div class="card-body card-body2">
          <p class="card-text">1- Ingresa al sitio, ingresa a la sección generar factura.</p>
        </div>
      </div>
    </div>
    <div class="containerCard">
      <div class="card">
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manualTicket" class="card-img-top" alt="...">
        <div class="card-body card-body2">
          <p class="card-text">2- Mantén a la mano tu ticket de compra.</p>
        </div>
      </div>
    </div>
    <div class="containerCard">
      <div class="card">
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manual1" class="card-img-top" alt="...">
        <div class="card-body card-body2">
          <p class="card-text">3-Ingresa los datos solicitados y da clic a continuar.</p>
        </div>
      </div>
    </div>
    <div class="containerCard">
      <div class="card">
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manual2" class="card-img-top" alt="...">
        <div class="card-body card-body2">
          <p class="card-text">4-Ingresa tus datos Fiscales y da clic a continuar.</p>
        </div>
      </div>
    </div>
    <div class="containerCard">
      <div class="card">
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manual3" class="card-img-top" alt="...">
        <div class="card-body card-body2">
          <p class="card-text">5- Podrás ver la información de tu compra, Da clic en descargar.</p>
        </div>
      </div>
    </div>
    <div class="containerCard">
      <div class="card">
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manual4" class="card-img-top" alt="">
        <div class="card-body card-body2">
          <p class="card-text">6- Listo! Ya tienes tu factura.</p>
        </div>
      </div>
    </div>
  </div>

  <div class=" containerHomeS3 mt-5 ">
    <div class="textMainHome2">
      <h2>Como Consultar tu Factura.</h2>
      <p>Mantén tu ticket a la mano.</p>
  </div>
  </div>
  <div class="container containerBoxCard mt-5 mb-5 ">
    <div class="containerCard">
      <div class="card">
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manual2_1" class="card-img-top" alt="...">
        <div class="card-body card-body2">
          <p class="card-text">1- Dirígete al menú, elige "Consultar Factura".</p>
        </div>
      </div>
    </div>
    <div class="containerCard">
      <div class="card">
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manual2_2" class="card-img-top" alt="...">
        <div class="card-body card-body2">
          <p class="card-text">2- Mantén a la mano tu ticket de compra, llena los campos requeridos.</p>
        </div>
      </div>
    </div>
    <div class="containerCard">
      <div class="card" >
        <img [src]="CONF?.staticContent?.modules?.faq?.img_manual2_3" class="card-img-top" alt="...">
        <div class="card-body card-body2">
          <p class="card-text">3- Listo!. Puede descargar su factura.</p>
        </div>
      </div>
    </div>
  </div>

