import { TypePropertyInput } from "src/interfaces/env.default.interface";
import { IEvents, IQuestionBase } from "src/interfaces/questions.interface";

export class QuestionBase<T> {
    value?: string;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    regex?: string;
    maxLength?: number;
    helpMessage?: string;
    event?: IEvents;
    showTooltip?: boolean;
    disabled?: boolean;
    advancedOptions: T;
    typeQuestion?: TypePropertyInput;
    
    constructor(options: IQuestionBase<T> = {} as IQuestionBase<T>) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label || '';
      this.required = !!options.required;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.regex = options?.regex || '';
      this.helpMessage = options?.helpMessage || '';
      this.event = options?.event || {};
      this.maxLength = options?.maxLength || 200;
      this.showTooltip = options?.showTooltip || false;
      this.disabled = options?.disabled || false;
      this.advancedOptions = options?.advancedOptions || {} as T;
    }
  }
