import { QuestionBase } from 'src/app/shared/classes/question-base';
import { IDropdown } from 'src/app/shared/classes/question-dropdown';
import {
  IConfiguration,
  TypePropertyInput,
} from 'src/interfaces/env.default.interface';
import {
  ICheckbox,
  IDate,
  IRadio,
  ITextbox,
} from 'src/interfaces/questions.interface';
import { Company } from '../interfaces/env.default.interface';

export const configSamsonite: IConfiguration = {
  staticContent: {
    modules: {
      customerContact: {
        img_contact: 'assets/farmacias/images/ImgC.JPG',
        link_facebook: 'https://www.facebook.com/samsonite',
        link_instagram: 'https://www.instagram.com/samsonite/',
        link_tiktok: 'https://www.tiktok.com/@samsonite',
        link_twitter: 'https://twitter.com/samsonite',
        link_youtube: 'https://www.youtube.com/user/samsonite',
        txt_desc_telephone:
          'Comunícate a nuestra Línea de SAMSONITE 800 888 3333 en un horario de atención de 9:00AM A 9:00PM de Lunes a Sábado.',
        txt_main: 'clientes@samsonite.com.mx',
        txt_telephone: '800 888 3333',
      },
      faq: {
        img_manual1: 'assets/farmacias/images/manual_img1.png',
        img_manual2: 'assets/farmacias/images/manual_img2.png',
        img_manual2_1: 'assets/farmacias/images/manual2_img1.png',
        img_manual2_2: 'assets/farmacias/images/manual2_img2.png',
        img_manual2_3: 'assets/farmacias/images/manual2_img3.png',
        img_manual3: 'assets/farmacias/images/manual_img3.png',
        img_manual4: 'assets/farmacias/images/manual_img4.png',
        img_manualPage_1: 'assets/farmacias/images/manual_imgPage.png',
        img_manualTicket: 'assets/farmacias/images/manual_imgTicket.png',
      },
      invoiceSearch: {
        alt_imgHeader: 'Imgen Tablet',
        alt_imgTicket: 'Ticket Samsonite',
        img_header: 'assets/samsonite/images/samsonite_header.jpg',
        img_ticket: 'assets/samsonite/images/factura_ejemplo.png',
      },
      invoices: {
        components: {
          confirmInvoice: {
            alt_imgHeader: 'Header Generar Factura',
            img_header: 'assets/farmacias/images/inside samsonite.jpg',
          },
          customerInformation: {
            img_header: 'assets/samsonite/images/samsonite_headerV2.jpg',
          },
          validateTicket: {
            alt_imgTicket: 'Ticket Samsonite',
            img_header: 'assets/samsonite/images/samsonite_header.jpg',
            img_ticket: 'assets/samsonite/images/samsonite_ticket.png',
            alt_imgRFC: 'Ejemplo RFC',
            img_RFC:'assets/samsonite/images/CSF-Ejemplo.jpg',
            
          },
        },
      },
    },
    shared: {
      components: {
        navbar: {
          img_logo_blackMode: 'assets/samsonite/images/logo_white.png',
          img_logo_default: 'assets/samsonite/images/logo.png',
        },
      },
    },
  },
  dynamicForms: {
    validateTicketForm: [
      {
        typeQuestion: TypePropertyInput.dropdown,
        key: 'Sucursal',
        label: 'Sucursal',
        type: 'dropdown',
        required: true,
        order: 1,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        event: {
          tooltipHelpButton: 'showModalITU',
        },
        advancedOptions: {
        },
      } as QuestionBase<IDropdown>,
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'Monto',
        label: 'Monto',
        required: true,
        order: 2,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        event: {
          tooltipHelpButton: 'showModalITU',
        },
        advancedOptions: {
          locale: 'es-MX',
          optLocaleString: {
            style: 'currency',
            currency: 'MXN',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          },
        },
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'TicketID',
        label: 'TicketID',
        required: true,
        order: 3,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        event: {
          tooltipHelpButton: 'showModalITU',
        },
        advancedOptions: {},
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'channelID',
        label: 'Canal de Venta',
        required: true,
        order: 4,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        event: {
          tooltipHelpButton: 'showModalITU',
        },
        advancedOptions: {},
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'RFC',
        label: 'RFC',
        required: true,
        order: 5,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        event: {
          tooltipHelpButton: 'showModalRFC',
        },
        regex:
          '[A-Z&amp;Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]',
        advancedOptions: {
          msgInCaseRegexFailed: 'El RFC no es valido, favor de verificarlo.',
          toUpperCase: true
        },
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.date,
        key: 'fechaVenta',
        label: 'Fecha de venta',
        type: 'date',
        required: false,
        order: 6,
        showTooltip: true,
        disabled: false,
        value: '',
        event: {
          tooltipHelpButton: 'showModalITU',
          onInputChange: 'onInputChangeCheckbox  ',
        },
        advancedOptions: {
          label: 'Fecha de venta',
          format: 'dd/MM/yyyy',
          maxDate: '2099-12-31',
          minDate: '2000-01-01'
        },
        helpMessage: 'Este campo es obligatorio.',
      } as QuestionBase<IDate>,
    ],
    consultaTicketForm: [],
    contactoTicketForm: [
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'Nombre',
        label: 'Nombre Completo',
        required: true,
        order: 1,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        regex: '',
        advancedOptions: {
          msgInCaseRegexFailed: '',
        },
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'Correo',
        label: 'Correo',
        required: true,
        order: 2,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        regex: '',
        advancedOptions: {
          msgInCaseRegexFailed: '',
        },
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'TicketID',
        label: 'TicketID',
        required: true,
        order: 3,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        regex: '',
        advancedOptions: {
          msgInCaseRegexFailed: '',
        },
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'Sucursal',
        label: 'Sucursal',
        required: true,
        order: 4,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        regex: '',
        advancedOptions: {
          msgInCaseRegexFailed: '',
        },
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.textbox,
        key: 'Asunto',
        label: 'Asunto',
        required: true,
        order: 5,
        showTooltip: true,
        helpMessage: 'Este campo es obligatorio.',
        regex: '',
        advancedOptions: {
          msgInCaseRegexFailed: '',
        },
      } as QuestionBase<ITextbox>,
      {
        typeQuestion: TypePropertyInput.date,
        key: 'Fecha',
        label: 'Fecha',
        type: 'date',
        required: false,
        order: 6,
        showTooltip: true,
        disabled: false,
        value: '',
        event: {
          tooltipHelpButton: 'showModalITU',
          onInputChange: 'onInputChangeCheckbox  ',
        },
        advancedOptions: {
          label: 'Fecha de venta',
          format: 'dd/MM/yyyy',
        },
        helpMessage: 'Fecha de venta',
      } as QuestionBase<IDate>,
    ],
  },
};
