

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterSectionComponent } from './components/Footer/footer-section.component';
import { AlertComponent } from './components/alert/alert.component';
import { ModalComponent } from './components/modal/modal.component';
import { ActionModalComponent } from './components/action-modal/action-modal.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { InputsFormulariosComponent } from './components/app-question/question.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    NavbarComponent,
    FooterSectionComponent,
    AlertComponent,
    ModalComponent,
    ActionModalComponent,
    InputsFormulariosComponent,
    DynamicFormComponent
    
  ],
  declarations: [
    NavbarComponent,
    FooterSectionComponent,
    AlertComponent,
    ModalComponent,
    ActionModalComponent,
    InputsFormulariosComponent,
    DynamicFormComponent
  ],
  providers: [],
})
export class SharedModule { }
