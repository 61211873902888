import { HttpRequest } from '@angular/common/http';
import { Component, ViewChild, Input } from '@angular/core';
import { ValidateTicketComponent } from '../../Components/ValidateTicket/validate-ticket.component';

@Component({
  selector: 'app-billing-ticket',
  templateUrl: './billing-ticket.component.html',
  styleUrls: ['./billing-ticket.component.css']
})
export class BillingTicketComponent {

  stateView: number = 0;
  addenda: any;
  finalRequestTicket: any;

  onContinueValidateTicket(addenda: any) {
    this.addenda = addenda;
    this.stateView = 1;
  }

  onContinueCustomerInformation(requestTicket: any) {
    this.finalRequestTicket = requestTicket;
    this.stateView = 2;
  }

  onReturnCustomerInformation() {
    this.stateView = 0;
  }

  onReturnBillingConfirmation() {
    this.stateView = 1;
  }
}
