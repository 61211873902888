import { IRadio } from 'src/interfaces/questions.interface';
import {  QuestionBase } from './question-base';

export class RadioQuestion extends QuestionBase<IRadio> {
  override controlType = 'radio';
  constructor(baseOptions: QuestionBase<IRadio> = {} as QuestionBase<IRadio>) {
    super(baseOptions);
  }  
}


