import { QuestionBase } from "src/app/shared/classes/question-base"

export interface Environment {
    enviromentType: EnviromentType,
    company: Company,
    apiUrl:string,
    configuration: IConfiguration,
    companyCode: string
}

// ----------------------------------------
// Enums 
// ----------------------------------------
export enum EnviromentType {
    Dev = 1,
    Prod = 2
}   

export enum Company{
    Farmacias = 1,
    Lvh = 2,
    Samsonite = 3
}

export enum TypePropertyInput{
    textbox = 1,
    dropdown = 2,
    checkbox = 3,
    radio = 4,
    date = 5,
}



// ----------------------------------------
// Interfaces
// ----------------------------------------
export interface IConfiguration {
    staticContent: {
        modules: {
            customerContact: any,
            faq: any,
            invoiceSearch: any,
            invoices: any,
        },
        shared: {
            components: {
                navbar: any
            }
        
        },
    },
    dynamicForms: {
        validateTicketForm: QuestionBase<any>[],
        consultaTicketForm: QuestionBase<any>[],
        contactoTicketForm: QuestionBase<any>[]
    },
}