import { ICheckbox, IDate } from 'src/interfaces/questions.interface';
import {  QuestionBase } from './question-base';

export class DateQuestion extends QuestionBase<IDate> {
  override controlType = 'date';
  constructor(baseOptions: QuestionBase<IDate> = {} as QuestionBase<IDate>) {
    super(baseOptions);
  }  
}


