import { Component, EventEmitter, Output, OnInit, ChangeDetectorRef } from '@angular/core';
import * as bootstrap from 'bootstrap';
import { TicketsService } from '../../services/tickets.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppComponent } from '../../../../../app/app.component';
import { FormGroup } from '@angular/forms';
import { QuestionService } from '../../services/question.service';
import { Observable, firstValueFrom, of } from 'rxjs';
import { QuestionBase } from 'src/app/shared/classes/question-base';
import { environment } from 'src/environments/env.default';
import { Company, TypePropertyInput } from 'src/interfaces/env.default.interface';
import { BaseService } from 'src/app/shared/services/base.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { Router } from '@angular/router';
import { IDropdown } from 'src/app/shared/classes/question-dropdown';

@Component({
  selector: 'app-validate-ticket',
  templateUrl: './validate-ticket.component.html',
  styleUrls: ['./validate-ticket.component.css'],
  providers: [QuestionService],
})
export class ValidateTicketComponent implements OnInit {
  public CONF: any = ConfigService.CONF; // load configuration by enviroment
  public showSpinnerProcess: boolean = false; // Used to show the spinner
  public showModalTicket: boolean = false;
  public showModalRFC: boolean = false;
  public showModalCfdi: boolean = false;
  public usosCfdiAll: any = {};
  public catSucursales: any = {};
  public catFormaPago: any = {};
  public usoCfdi: string = '';
  public ticket: string = '';
  public readyRfc: boolean = false;
  public readyItu: boolean = false;
  public generateInvoice: boolean = false;
  public urlPdf: string = '';
  public urlXml: string = '';
  public requestAux: any;
  questions$: Observable<QuestionBase<any>[]> = of([]);
  @Output('on-continue') continueEvent: EventEmitter<any> = new EventEmitter();
  public actionModalInfo = {
    isOpen: false,
    titleModal: '',
    content: '',
    showCancelButton: false,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Confirmar',
    onCloseEvent: () => {},
    onCancelEvent: () => {},
  };

  constructor(
    private http: HttpClient,
    private ts: TicketsService,
    private app: AppComponent,
    private qService: QuestionService,
    private cdr: ChangeDetectorRef,
    private config: ConfigService,
    private router: Router
  ) {
    const url = this.app.env.apiUrl + 'ConsultaCif/CatalogoUsoCfdi';
    http.get(url).subscribe({
      next: (result) => {
        this.usosCfdiAll = result;
      },
      error: console.error,
    });

    this.questions$ = qService.getQuestions_ValidateTicketForm();

    // Used to listen events from QuestionService
    this.qService.events$.subscribe((event) => this.manageEvent(event));
  }

  ngOnInit() {
    // load configuration by enviroment
    this.config.getConfigurationAsync().subscribe((configuration) => {
      this.CONF = ConfigService.CONF;
    });
    if (environment.company == Company.Samsonite) {
      this.getSucursales();
      //this.getFormaPago();
    }
  }

  async getSucursales() {
    // Construir los parámetros del query string
    const params = new HttpParams().set('companyCode', environment.companyCode);

    const urlCatalogoSucursales =
      this.app.env.apiUrl + 'Sucursales/CatalogoSucursales';
    try {
      const result = await firstValueFrom(
        this.http.get(urlCatalogoSucursales, { params })
      );
      this.catSucursales = result;
      this.mapeaCatSucursales();
      this.questions$ = this.qService.getQuestions_ValidateTicketForm();
    } catch (error) {
      console.error(error);
    } finally {
      this.cdr.detectChanges();
    }
  }

  async getFormaPago() {

    const urlCatalogoFormaDePago =
      this.app.env.apiUrl + 'ConsultaCif/CatalogoFormaDePago';
    try {
      const result = await firstValueFrom(
        this.http.get(urlCatalogoFormaDePago)
      );
      this.catFormaPago = result;
      this.mapeaCatFormaPago();
      this.questions$ = this.qService.getQuestions_ValidateTicketForm();
    } catch (error) {
      console.error(error);
    } finally {
      this.cdr.detectChanges();
    }
  }

  mapeaCatSucursales() {
    const arrayOptions = this.getArrayCatSucursales();
    if (environment.company == Company.Samsonite) {
      this.CONF.dynamicForms.validateTicketForm[0].advancedOptions = {
        options: arrayOptions,
      };
    }
  }

  mapeaCatFormaPago() {
    const arrayOptions = this.getArrayCatFormaPago();
    if (environment.company == Company.Samsonite) {
      this.CONF.dynamicForms.validateTicketForm[6].advancedOptions = {
        options: arrayOptions,
      };
    }
  }

  getArrayCatSucursales() {
    const arreglo: any[] = [];
    this.catSucursales.forEach(
      (sucursal: { codigoSucursal: string; nombre: string }) => {
        const leyenda =
          sucursal.codigoSucursal.padStart(3, '0') + ' - ' + sucursal.nombre;
        arreglo.push({ key: sucursal.codigoSucursal, value: leyenda });
      }
    );
    return arreglo;
  }

  getArrayCatFormaPago() {
    const arreglo: any[] = [];
    this.catFormaPago.forEach(
      (formaPago: { text: string; value: string }) => {
        const leyenda = formaPago.text;
        arreglo.push({ key: formaPago.value, value: leyenda });
      }
    );
    return arreglo;
  }

  onListenFormValue(data: any) {
    // Case: Farmacias
    if (environment.company == Company.Farmacias) {
      this.businessLogicFarmacias(data);
    }
    // Case: LVH
    else if (environment.company == Company.Lvh) {
      // ...
    }
    // Case: Samsonite
    else {
      this.businessLogicSamsonite(data);
    }
  }

  businessLogicFarmacias(data: any) {
    this.showSpinnerProcess = true;

    const url = this.app.env.apiUrl + 'ConsultaCif/RequestTicket';
    const body =
      '{"numTicket": "' +
      data.numTicket +
      '","rfcReceptor": "' +
      data.rfc +
      '"  }';
    const headers = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    };

    this.http.post<any>(url, body, headers).subscribe({
      next: (request) => {
        this.showSpinnerProcess = false;

        // Case: Ticket Exist
        if (request.cabecera != null) {
          this.urlPdf = request.cabecera.urlPdf;
          this.urlXml = request.cabecera.urlXml;
          this.qService.updateResponseValidation({
            urlPdf: this.urlPdf,
            urlXml: this.urlXml,
            ticketFacturadoAlert: true,
          });
        }

        // Case: Ticket Not Exist
        else {
          request.requestCFD.comprobante.receptor.usoCfdi = this.usoCfdi;
          request.requestCFD.comprobante.folio = this.ticket;
          this.continueEvent.emit(request);
        }
      },
      error: (error) => {
        this.showSpinnerProcess = false;
        console.log(error);

        this.qService.updateResponseValidation({
          error: true,
          message: 'Ha ocurrido el siguiente error: ' + error.message,
        });
      },
    });
  }

  businessLogicSamsonite(data: any) {
    this.showSpinnerProcess = true;

    const request = {
      Sucursal: data.Sucursal,
      Monto: data.Monto.replace(/[^0-9.]/g, ''),
      Fecha: data.fechaVenta,
      RfcReceptor: data.RFC,
      NumTicket: data.TicketID,
      ChannelID: data.channelID,
      CompanyCode: environment.companyCode,
    };

    const url = this.app.env.apiUrl + 'ConsultaCif/RequestTicket';
    const body = JSON.stringify(request).toString();
    const headers = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    };

    this.http.post<any>(url, body, headers).subscribe({
      next: (request) => {
        this.showSpinnerProcess = false;

        const actionToDo: string = request.answerCode;
        if (actionToDo === '001') {
          request.requestCFD.comprobante.receptor.usoCfdi = this.usoCfdi;
          this.continueEvent.emit(request);
        }
        if (actionToDo === '002') {
          this.requestAux = request;
          this.showConfirmReInvoice(
            'Ticket facturado previamente',
            'El ticket ya fue facturado previamente. ¿Desea refacturarlo?'
          );
        }
        if (actionToDo === '100') {
          this.showWaitModal(
            'Espere por favor',
            'El ticket aun no se puede facturar'
          );
        }
        if (actionToDo === '101') {
          this.showWaitModal(
            'No se puede facturar el ticket',
            'El total del ticket ha sido devuelto'
          );
        }
        if (actionToDo === '102') {
          this.showWaitModal(
            'No se puede facturar el ticket',
            'Se ha excedido el rango de fecha para facturar el ticket.'
          );
        }
        if (actionToDo === '103') {
          this.showWaitModal(
            'No se encontró el ticket',
            'No se encontró el ticket, valide su información.'
          );
        }
        if (actionToDo === '104') {
          this.showWaitModal(
            'No se encontró el ticket',
            'No se encontró el ticket, valide su información.'
          );
        }
        if (actionToDo === '105') {
          this.requestAux = request;
          this.showWaitModal(
            'Ticket de periodo fiscal anterior',
            'El ticket pertenece a un periodo fiscal anterior. No se puede facturar.'
          );
        }
      },
      error: (error) => {
        this.showSpinnerProcess = false;
        console.log(error);

        this.qService.updateResponseValidation({
          error: true,
          message: 'Ha ocurrido el siguiente error: ' + error.message,
        });
      },
    });
  }

  onModalRFC() {
    this.showModalRFC = !this.showModalRFC;
  }
  onModalCfdi() {
    this.showModalCfdi = !this.showModalCfdi;
  }
  onModalTicket() {
    this.showModalTicket = !this.showModalTicket;
  }

  // Metodo para manejar los eventos emitidos por los inputs dinamicos  atraves del QuestionService
  manageEvent(event: string) {
    switch (event) {
      case 'showModalRFC':
        this.showModalRFC = true;
        break;
      case 'showModalITU':
        this.showModalTicket = true;
        break;
      case 'onInputChangeRFC':
        if (this.qService.form.get('rfc')?.valid)
          this.qService.form.get('ITU')?.enable();
        break;
      default:
        break;
    }
  }

  showWaitModal(title: string, message: string): void {
    this.actionModalInfo = {
      isOpen: true,
      titleModal: title,
      content: message,
      showCancelButton: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      onCloseEvent: this.onWaitTicket.bind(this),
      onCancelEvent: () => {},
    };
  }

  onWaitTicket(): void {
    this.actionModalInfo = {
      isOpen: false,
      titleModal: '',
      content: '',
      showCancelButton: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      onCloseEvent: () => {},
      onCancelEvent: () => {},
    };
    this.router.navigate(['/creafactura']);
  }

  continueInvoice() {
    this.requestAux.requestCFD.comprobante.receptor.usoCfdi = this.usoCfdi;
    this.continueEvent.emit(this.requestAux);
  }

  showConfirmReInvoice(title: string, message: string): void {
    this.actionModalInfo = {
      isOpen: true,
      titleModal: title,
      content: message,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      onCloseEvent: this.continueInvoice.bind(this),
      onCancelEvent: this.onlyCloseModal.bind(this),
    };
  }

  onlyCloseModal(): void {
    this.actionModalInfo = {
      isOpen: false,
      titleModal: '',
      content: '',
      showCancelButton: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      onCloseEvent: () => {},
      onCancelEvent: () => {},
    };
  }

  testModal() {}
}

