import { ChangeDetectorRef, Component } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment } from 'src/environments/env.default';

@Component({
  selector: 'app-home-invoice',
  templateUrl: './home-invoice.component.html',
  styleUrls: ['./home-invoice.component.css']
})
export class HomeInvoiceComponent {
  public CONF: any = ConfigService.CONF;// load configuration by enviroment
  public mostrarModal: boolean = true;
  public isChecked: boolean = false;
  private modalLocalStorageKey = 'modalShown';
  public isContentVisible = false;

  constructor(private localStorageService: LocalStorageService, private cdr: ChangeDetectorRef, private config: ConfigService) {
    const modalShown = this.localStorageService.getItem(this.modalLocalStorageKey);
    if (modalShown) {
      this.mostrarModal = false;
    }
  }
  ngOnInit() {
    this.config.getConfigurationAsync().subscribe((configuration) => { this.CONF = ConfigService.CONF; this.cdr.detectChanges();});
  }

  hiddenModal() {
    this.mostrarModal = false;
    this.localStorageService.setItem(this.modalLocalStorageKey, 'true');
  }
  toggleContentVisibility() {
    this.isContentVisible = !this.isContentVisible;
  }
}
