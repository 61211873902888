import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmInvoiceComponent } from './Components/ConfirmInvoice/confirm-invoice.component';
import { CustomerInformationComponent } from './Components/CustomerInformation/customer-information.component';
import { ValidateTicketComponent } from './Components/ValidateTicket/validate-ticket.component';
import { BillingTicketComponent } from './Pages/BillingTicket/billing-ticket.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeInvoiceComponent } from '../home-invoice/home-invoice.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';


const routes: Routes = [
  {
    path: '',
    component: BillingTicketComponent,
  }
];


@NgModule({
  imports: [

    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,

    HttpClientModule,
    ReactiveFormsModule,
    SharedModule

  ],
  exports: [

  ],
  declarations: [
    ConfirmInvoiceComponent,
    CustomerInformationComponent,
    ValidateTicketComponent,
    BillingTicketComponent,
    HomeInvoiceComponent,


  ],
  providers: [],
})
export class InvoiceModule { }

