import { QuestionBase } from './question-base';

export class DropdownQuestion extends QuestionBase<IDropdown> {
  override controlType = 'dropdown';

  constructor(baseOptions: QuestionBase<IDropdown> = {} as QuestionBase<IDropdown>) {
    super(baseOptions);
  }
}

export interface IDropdown{
  options: {key: string, value: string }[];
}
