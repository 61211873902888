import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment } from 'src/environments/env.default';
import { Company } from 'src/interfaces/env.default.interface';
import { AppComponent } from 'src/app/app.component';
import { HttpClient } from '@angular/common/http';
import { QuestionBase } from 'src/app/shared/classes/question-base';
import { QuestionService } from '../Invoices/services/question.service';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-customer-contact',
  templateUrl: './customer-contact.component.html',
  styleUrls: ['./customer-contact.component.css'],
  providers: [QuestionService],
})
export class CustomerContactComponent {
  public CONF: any = ConfigService.CONF;// load configuration by enviroment
  public showSpinnerProcess: boolean = false; // Used to show the spinner
  @Output('on-continue') continueEvent: EventEmitter<any> = new EventEmitter();
  public requestAux : any;
  public actionModalInfo = {
    isOpen: false, titleModal: '', content: '', onCloseEvent: () => { }
  };

  nameForm:string = '';
  emailForm:string = '';
  ticketForm:string = '';
  sucursalForm:string = '';
  subjetForm:string = '';
  dateForm:string = '';
  mensajeForm:string = '';

  constructor(
    private cdr: ChangeDetectorRef, 
    private config: ConfigService,
    private app: AppComponent,
    private http: HttpClient,
    private qService: QuestionService,
    private router: Router
    ){

  }

  ngOnInit() {
    this.config.getConfigurationAsync().subscribe((configuration) => { 
      this.CONF = ConfigService.CONF; 
      this.cdr.detectChanges();
    });
  }

  onListenFormValue(data: any) {
    // Case: Farmacias
    if (environment.company == Company.Farmacias) {
      //this.businessLogicFarmacias(data);
    }
    // Case: LVH
    else if (environment.company == Company.Lvh) {
      // ...
    }
    // Case: Samsonite
    else {
      this.businessLogicSamsonite(data);
    }
  }

  businessLogicSamsonite(data: any) {
    this.showSpinnerProcess = true;
    this.cdr.detectChanges();

    const request = {
      NombreCliente: this.nameForm,
      EmailCliente: this.emailForm,
      NumTicket: this.ticketForm,
      FechaTicket: this.dateForm,
      NumSucursal: this.sucursalForm,
      Comentario: this.mensajeForm,
      Asunto: this.subjetForm,
      CompanyCode: environment.companyCode,
    };

    const url = this.app.env.apiUrl + 'Contacto/EnviaCorreoContacto';
    const body = JSON.stringify(request).toString();
    const headers = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    };

    this.http.post<any>(url, body, headers).subscribe({
      next: (request) => {
        this.showSpinnerProcess = false;

        const actionToDo: string = request.codigoRetorno;
        if (actionToDo === '001') {
          this.showWaitModal(
            "Se envio el correo",
            request.mensaje
            );
        }
        if (actionToDo === '101') {
          this.showWaitModal(
            "Se envio el correo",
            request.mensaje
            );
        }
      },
      error: (error) => {
        this.showSpinnerProcess = false;
        console.log(error);

        this.qService.updateResponseValidation({
          error: true,
          message: 'Ha ocurrido el siguiente error: ' + error.message,
        });
      },
    });
  }

  showWaitModal(title : string, message : string): void {
    this.actionModalInfo = {
      isOpen: true,
      titleModal: title,
      content: message,
      onCloseEvent: this.onWaitTicket.bind(this),
    };
  }

  onWaitTicket(): void {
    this.actionModalInfo = {
      isOpen: false,
      titleModal: '',
      content: '',
      onCloseEvent: () => { }
    };
    //this.router.navigate(['/contacto']);
    location.reload();
  }
}
