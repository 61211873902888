import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable, map, of } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl:'./navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  public CONF: any = ConfigService.CONF;// load configuration by enviroment

  public UlStatus: boolean = true;
  public toggle: boolean = false;
  public icon: string = 'bi-list';

  public darkModeEnabled: boolean = false;

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef, private config: ConfigService) {  
    const darkModeValue = localStorage.getItem('darkModeEnabled');
    if (darkModeValue !== null) {
        this.darkModeEnabled = darkModeValue === 'true';
        this.applyTheme();
    }
  }

  ngOnInit() {    
    this.config.getConfigurationAsync().subscribe((configuration) => {  this.CONF = ConfigService.CONF; this.cdr.detectChanges(); });
  }

  toggleMenu() {
    this.toggle = !this.toggle;
    this.icon = this.toggle ? 'bi-x-lg' : 'bi-list';
    this.UlStatus = !this.UlStatus;
  }

  toggleDarkMode() {
    this.darkModeEnabled = !this.darkModeEnabled;
    localStorage.setItem('darkModeEnabled', this.darkModeEnabled.toString());
    this.applyTheme();
    console.log(window.matchMedia)
  }

  applyTheme() {
    if (this.darkModeEnabled) {
      this.renderer.setAttribute(document.body, 'data-bs-theme', 'dark');
    } else {
      this.renderer.removeAttribute(document.body, 'data-bs-theme');
    }
  }
}
