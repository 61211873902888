import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, interval, map, of, takeWhile } from 'rxjs';
import { environment } from 'src/environments/env.default';
import { Company } from 'src/interfaces/env.default.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public static CONF:any = null; // Define la configuración de la aplicación
  public confAsync = new Subject<any>();

  // TODO: Configuration to load Config from API, uncomment this code if you want to load configuration from API
  //public CONFIG_URL = environment.configuration.apiUrl; 


  constructor(private http: HttpClient) {
    this.loadConfiguration().subscribe((configuration) => {
      if(configuration == null) {return;} 
      environment.configuration = configuration; // update environment // TODO: Configuration to load Config from API, uncomment this code if you want to load configuration from API
      ConfigService.CONF = configuration; // update static variable
      this.confAsync.next(configuration); // notify to subscribers

    });
  }

  getConfigurationAsync(){
    return this.confAsync.asObservable();
  }

  loadConfiguration(){
    // TODO: Configuration to load Config from API, uncomment this code if you want to load configuration from API
    /*
    let _company = ""
    switch(environment.company){
      case Company.Farmacias:
        _company = "farmacias";
        break;
      case Company.Samsonite:
        _company = "samsonite";
        break;
    }
    return this.http.get<any[]>(this.CONFIG_URL + _company);
    */
   return of(environment.configuration);
  }
}