import { Component, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppComponent } from '../../../../app.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/env.default';
import { BaseService } from 'src/app/shared/services/base.service';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-confirm-invoice',
  templateUrl: './confirm-invoice.component.html',
  styleUrls: ['./confirm-invoice.component.css']
})
export class ConfirmInvoiceComponent {
  public CONF: any = ConfigService.CONF;// load configuration by enviroment
  @Input() data: any;
  public actionModalInfo = {
    isOpen: false, titleModal: '', content: '', onCloseEvent: () => { }
  };
  public ticket: any;
  public requestTicket: any;
  public urls: any;
  public conceptos: any = [];
  public ivaTotal: number = 0.00;
  public iepsTotal: string = "00.00";
  public message: string = "Ha ocurrido el siguiente error: ";
  public error: boolean = false;
  public c: any = [];
  public rfc: string = "";
  public itu: string = "";
  public process: boolean = false;
  constructor(private http: HttpClient, private app: AppComponent, private router: Router, private cdr: ChangeDetectorRef, private config: ConfigService) {

  }

  public existsPdf: boolean = false;
  public existsXml: boolean = false;
  public showStatus: boolean = true;
  public generateInvoice: boolean = false;
  changeShowStatus() {
    this.showStatus = !this.showStatus;
    //console.log(this.showStatus);
  }

  @Output("on-return") returnEvent: EventEmitter<any> = new EventEmitter();
  ticketDetailsVisibility: boolean = false;
  buttonTicketDetailsVisibilityLabel_On: string = "Mostar Detalles";
  buttonTicketDetailsVisibilityLabel_Off: string = "Ocultar Detalles";
  buttonTicketDetailsVisibilityLabel: string = this.buttonTicketDetailsVisibilityLabel_On;

  clickReturn() {
    this.returnEvent.emit();
  }

  changeGenerateInvoice() {
    this.process = true;

    this.requestTicket.comprobante = this.ticket
    document.querySelectorAll("#GenerarFactura")[0].setAttribute("disabled", "disabled");
    this.http.post<any>(this.app.env.apiUrl + 'ConsultaCif/EmiteTicket', JSON.stringify(this.requestTicket), { headers: { 'Content-Type': 'application/json' }, withCredentials: true }).subscribe(result => {
      
      if (result.urlPdf) {
        this.existsPdf = true;
        result.urlPdf = `${this.app.env.apiUrl}ConsultaCif/Descarga/?qr=${result.urlPdf}`;
      } else {
        this.existsPdf = false;
      }
      if (result.urlXml) {
        this.existsXml = true;
        result.urlXml = `${this.app.env.apiUrl}ConsultaCif/Descarga/?qr=${result.urlXml}`;
      }
      else {
        this.existsXml = false;
      }
      
      this.urls = result;
      this.process = false;

      console.log(result);
      if (result.exito==null || result.exito) {
        this.generateInvoice = !this.generateInvoice;
      }
      else {
        this.error = !this.error;
        this.message = this.message+result.message;
      }
    }, (error) => { this.process = false; this.handleHttpError(error); });
  }
  ngOnInit() {
    this.config.getConfigurationAsync().subscribe((configuration) => { this.CONF = ConfigService.CONF; this.cdr.detectChanges();});
    this.process = false;
    //console.log(this.data);
    this.requestTicket = this.data;
    //console.log(this.requestTicket);
    this.ticket = this.data.comprobante;
    this.itu = this.ticket.addendaTicket.numTicket;
    this.rfc = this.ticket.receptor.rfc;
    //this.conceptos = this.ticket.conceptos;
    this.ticket.impuestos.traslados.forEach( (item:any) => {
      //console.log(item.impuesto);
      if (item.impuesto == "002") {
        this.ivaTotal += item.importe;
      }
      else if (item.impuesto == "003") {
        this.iepsTotal += item.importe;
      }
    });
    this.ticket.conceptos.forEach((item: any) => {

      this.c = new Array<any>();
      this.c["descripcion"] = item.descripcion;
      this.c["cantidad"] = item.cantidad;
      this.c["valorUnitario"] = item.valorUnitario;
      this.c["importe"] = item.importe;
      this.c["porIva"] = "0";
      this.c["impIva"] = "00.00";
      this.c["impIeps"] = "00.00";

      item.impuestos.traslados.forEach((imp: any) => {
        if (imp.impuesto == "002") {
          this.c["porIva"] = imp.tasaOCuota;
          this.c["impIva"] = imp.importe;
        }
        else if (imp.impuesto == "003") {
          this.c["impIeps"] = imp.importe;
        }
      });

      this.conceptos.push(this.c);
    });
    //console.log(this.conceptos);
  }
  downloadPdf() {

  }
  downloadXml() {

  }
  showTicketDetails() {
    this.ticketDetailsVisibility = !this.ticketDetailsVisibility;
    if(this.ticketDetailsVisibility)
      this.buttonTicketDetailsVisibilityLabel = this.buttonTicketDetailsVisibilityLabel_Off;
    else
      this.buttonTicketDetailsVisibilityLabel = this.buttonTicketDetailsVisibilityLabel_On;
  }

  showSessionExpireModal(): void {
    this.actionModalInfo = {
      isOpen: true,
      titleModal: 'Su sesión ha expirado',
      content: 'El tiempo de sesión ha sido excedido. Volverá a la página principal.',
      onCloseEvent: this.onCloseSession.bind(this)
    };
  }

  onCloseSession(): void {
    this.actionModalInfo = {
      isOpen: false,
      titleModal: '',
      content: '',
      onCloseEvent: () => { }
    };
    this.router.navigate(['/home']);
  }

  private handleHttpError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      // Handle HTTP errors
      if (error.status === 401) {
        // Handle 401 error by triggering a token refresh
        this.showSessionExpireModal();
      } else {
        // Handle other HTTP errors
        console.error('HTTP error:', error);
      }
    } else {
      console.error('Error:', error);
    }
  }

}
