import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionBase } from '../classes/question-base';

@Injectable()
export class QuestionControlService {
  constructor() {}

  toFormGroup(questions: QuestionBase<any>[]) {
    const group: any = {};

    questions.forEach((question) => {
      group[question.key] = new FormControl(this.loadControlState(question), this.loadValidators(question));
    });
    return new FormGroup(group);
  }

  private loadValidators(question: QuestionBase<any>){
    let validators:any = [];

    if(question.required){
      validators.push(Validators.required)
    }

    if(question.regex){
      validators.push(Validators.pattern(question.regex))
    }

    return validators;
  }

  private loadControlState(question: QuestionBase<any>){
    return {
      value: question.value || '',
      disabled: question.disabled || false
    };    
  }


}
