import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @Input() isOpen: boolean = false;
  @Input() title: string = '';
  @Input() imgBody: string | null = null;
  @Input() txtBody: string | null = null;
  @Input() typeModal: typeModal = typeModal.tooltip;
  @Output() onClose = new EventEmitter<void>();
}

export enum typeModal {
  tooltip,
}
