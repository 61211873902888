import { ICheckbox } from 'src/interfaces/questions.interface';
import {  QuestionBase } from './question-base';

export class CheckboxQuestion extends QuestionBase<ICheckbox> {
  override controlType = 'checkbox';
  constructor(baseOptions: QuestionBase<ICheckbox> = {} as QuestionBase<ICheckbox>) {
    super(baseOptions);
  }  
}


