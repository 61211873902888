<!--<app-navs-items></app-navs-items>-->
<app-navbar></app-navbar>
<router-outlet></router-outlet>
<!--Este sera el contenedor principal-->
<div class="containerMain">
    <!------------Contenedor de la seccion CONTENT Start------------->



    <!------------Contenedor de la seccion CONTENT end------------->
</div>

<app-footer-section></app-footer-section>

<!-- Component for Alert -->
<app-alert></app-alert>
