<div class="containerInvoiceTwo">
  <!--
    <div id="FullSpinner" *ngIf="process">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
    -->
  <div class="containerSpinner" *ngIf="process">
    <div class="spinner-grow text-primary m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </symbol>
  </svg>

  <div class="alert alert-light   alert-dismissible fade show alert-dismissible-serch text-primary-emphasis" role="alert">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill" /></svg>
    ¡Recuerda! Para generar tu factura, por favor ingresa los datos solicitados a continuación. Será indispensable contar con su Constancia de Situación Fiscal a la mano.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>


  </div>
  <div class=" container-fluid HeaderInvoiceOne mt-lg-2 bg-primary-subtle bg-opacity-10">
    <div class="containerHeaderInvoiceOneT">

      <h3 class="text-center h2style pb-lg-4 pb-md-4 pb-3">Datos Fiscales.</h3>
      <p>
        La siguiente información deberá coincidir con la contenida en la Cédula de Identificación Fiscal <br>y
        Constancia de Situación Fiscal.
      </p>
    </div>
    <div class="containerHeaderInvoiceOneImg">
      <img [src]="CONF?.staticContent?.modules?.invoices?.components?.customerInformation?.img_header" class="img-fluid" alt="...">
    </div>
  </div>
  <div class="container d-flex row m-auto  ">

    <form #frmDatos="ngForm" (ngSubmit)="onSubmitForm()" class="row gy-4 gx-4 m-0">

      <div class="col-md-12 col-lg-12 col-sm-12">
        <input id="file-input" type="file" class="file-input" name="file-input" (change)="onLoadCIF($event)" onclick="this.value = null" accept=".pdf" />
        <label class="btn btn-outline-primary col-lg-2 col-md-3 col-12 mt-lg-0 mt-md-0 pb-2 pt-2" for="file-input">
          <span><i class="bi bi-file-earmark-pdf"></i> Cargar CIF</span>
        </label>
      </div>

      <div class="row pl-0 gy-2">
        <div class="col-md-6 col-lg-4 col-sm-12 ">
          <label for="exampleFormControlInput1" class="form-label col">
            RFC del Cliente<button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo RFC', 'assets/images/Rfc.png', 'assets/images/Rfc.png')"><i class="bi bi-question-circle-fill"></i></button>
          </label>
          <input type="text" class="form-control" id="exampleFormControlInput1" [(ngModel)]="requestTicket.receptor.rfc" [ngModelOptions]="{standalone: true}" (input)="rfcChange()" (ngModelChange)="requestTicket.receptor.rfc = $event.toUpperCase()" disabled uppercase required maxlength="13">
          <!--<small id="emailHelp" class="form-text text-muted">Consta de 12 o 13 caracteres. </small>-->
        </div>
        <div class="col-md-6 col-lg-8 col-sm-12 ">
          <label for="razonSocial" class="form-label w-100">Razón Social <button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo Razón Social', 'assets/images/Razon_social.png', 'assets/images/Razon_social.png')"><i class="bi bi-question-circle-fill"></i></button></label>
          <input type="text" class="form-control {{(this.requestTicket.receptor.nombre == null || this.requestTicket.receptor.nombre.length < 1)?'is-invalid': 'is-valid'}}" name="razonSocial" id="razonSocial" #razonSocial="ngModel" [(ngModel)]="requestTicket.receptor.nombre" (ngModelChange)="requestTicket.receptor.nombre = $event.toUpperCase()" uppercase [ngModelOptions]="{standalone: true}" required minlength="4">
          <!--<small *ngIf="razonSocial.invalid && (razonSocial.dirty || razonSocial.touched)" id="emailHelp" class="form-text text-muted">
        Por favor, proporcione su Razón Social.

      </small>-->
        </div>
      </div>
      <div class="row pl-0 gy-2">

        <div class="col-md-6 col-lg-4 col-sm-12">
          <label for="inputRF" class="form-label">Régimen Fiscal <button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo Régimen Fiscal', 'assets/images/regimen.png', 'assets/images/calle.png')"><i class="bi bi-question-circle-fill"></i></button></label>
          <select id="inputRF" class="form-select {{(requestTicket.receptor.regimenFiscalReceptor == '0'|| requestTicket.receptor.regimenFiscalReceptor == ''|| requestTicket.receptor.regimenFiscalReceptor == null) ? 'is-invalid' : 'is-valid'
          }}" [(ngModel)]="requestTicket.receptor.regimenFiscalReceptor" [ngModelOptions]="{standalone: true}" (click)="rfcChange()" (change)="updateRegimen()" [disabled]="isDisabledRegFiscal">
            <option value="0">Seleccione...</option>
            <option *ngFor="let item of regimenFiscalFilter" value="{{item.clave}}"> {{ item.descripcion }}</option>
          </select>
          <small id="emailHelp" class="form-text text-muted">Régimen fiscal es requerido por el SAT. </small>
        </div>

        <div class="col-md-6 col-lg-4 col-sm-12">
          <label for="inputState" class="form-label">
            Uso de CFDI
          </label>
          <select id="inputState" class="form-select {{(requestTicket.receptor.usoCfdi.length<2) ? 'is-invalid' : 'is-valid'}}" 
          [(ngModel)]="requestTicket.receptor.usoCfdi" [ngModelOptions]="{standalone: true}" (change)="usoChange()" [disabled]="isDisabledUsoCfdi">
            <option value="a" [selected]="true">Seleccione....</option>
            <option *ngFor="let uso of usosCfdiFilter"
                    [selected]="uso.clave == 'G03' ? true : false" value="{{uso.clave}}">
              {{uso.descripcion}}
            </option>
          </select>
        </div>

        <div class="col-md-6 col-lg-4 col-sm-12" *ngIf="showFormaDePago">
          <label for="inputState" class="form-label">
            Uso de Forma de Pago
          </label>
          <select id="inputState" class="form-select {{(requestTicket.formaPago.length<2) ? 'is-invalid' : 'is-valid'}}"
          [(ngModel)]="requestTicket.formaPago" [ngModelOptions]="{standalone: true}">
            <option value="a" [selected]="true">Seleccione....</option>
            <option *ngFor="let formaPago of catFormaPago"
                     value="{{formaPago.value}}">
              {{formaPago.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="inputAddress2" class="form-label">Calle<button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo Calle', 'assets/images/calle.png', 'assets/images/calle.png')"><i class="bi bi-question-circle-fill"></i></button></label>
        <input type="text" class="form-control" id="inputAddress2" [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.calle" (ngModelChange)="requestTicket.receptor.addendaDomicilioExpedidoEn.calle = $event.toUpperCase()" [ngModelOptions]="{standalone: true}" required>
      </div>
      <div class="col-md-6 col-lg-2 col-sm-12 ">
        <label for="inputCity" class="form-label ">Número Exterior<button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo Número Exterior', 'assets/images/numeroExt.PNG', 'assets/images/calle.png')"><i class="bi bi-question-circle-fill"></i></button></label>
        <input type="text" class="form-control" id="inputCity" required [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.noExterior" (ngModelChange)="requestTicket.receptor.addendaDomicilioExpedidoEn.noExterior = $event.toUpperCase()" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="col-md-6 col-lg-2 col-sm-12 ">
        <label for="inputCity" class="form-label">Número Interior<button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo Número Interior', 'assets/images/numeroInt.PNG', 'assets/images/calle.png')"><i class="bi bi-question-circle-fill"></i></button></label>
        <input type="text" class="form-control" id="inputCity" required [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.noInterior" (ngModelChange)="requestTicket.receptor.addendaDomicilioExpedidoEn.noInterior = $event.toUpperCase()" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="Colonia" class="form-label ">Colonia <button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo Colonia', 'assets/images/colonia.PNG', 'assets/images/calle.png')"><i class="bi bi-question-circle-fill"></i></button></label>
        <input type="text" class="form-control " id="Colonia" required [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.colonia" (ngModelChange)="requestTicket.receptor.addendaDomicilioExpedidoEn.colonia = $event.toUpperCase()" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="Municipio" class="form-label">Alcaldía / Municipio <button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo Delegación/Municipio', 'assets/images/municipio.PNG', 'assets/images/calle.png')"><i class="bi bi-question-circle-fill"></i></button></label>
        <input type="text" class="form-control " id="Municipio" required [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.municipio" (ngModelChange)="requestTicket.receptor.addendaDomicilioExpedidoEn.municipio = $event.toUpperCase()" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12">
        <label for="inputState" class="form-label">Estado <button type="button" class="btn btn-primary buttonTooltip " (click)="openModal('Ejemplo Estado', 'assets/images/estado.PNG', 'assets/images/calle.png')"><i class="bi bi-question-circle-fill"></i></button></label>
        <input type="text" class="form-control " id="inputState" required [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.estado" (ngModelChange)="requestTicket.receptor.addendaDomicilioExpedidoEn.estado = $event.toUpperCase()" [ngModelOptions]="{standalone: true}">


        <!--<select id="inputState" class="form-select" [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.estado" [ngModelOptions]="{standalone: true}">
      <option *ngFor="let item of estados" value="{{item.text?.toUpperCase() }}"> {{ item.text?.toUpperCase() }}</option>
    </select>-->
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12">
        <label for="inputCountry" class="form-label">País</label>
        <input type="text" class="form-control" id="inputCountry" required [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.pais" (ngModelChange)="requestTicket.receptor.addendaDomicilioExpedidoEn.pais = $event.toUpperCase()" [ngModelOptions]="{standalone: true}">
       


        <!--<select id="inputCountry" class="form-select" [(ngModel)]="requestTicket.receptor.addendaDomicilioExpedidoEn.pais" [ngModelOptions]="{standalone: true}">
      <option *ngFor="let item of paises" value="{{item.text}}">{{item.text}}</option>
    </select>-->
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="residenciaFiscal" class="form-label">Código Postal<button type="button" class="btn btn-primary buttonTooltip" (click)="openModal('Ejemplo Código Postal', 'assets/images/cp.png', 'assets/images/calle.png')"><i class="bi bi-question-circle-fill"></i></button></label>
        <input type="text" class="form-control {{(requestTicket.receptor.residenciaFiscal?.length<5) ? 'is-invalid' : 'is-valid'}}" id="residenciaFiscal" required [(ngModel)]="requestTicket.receptor.residenciaFiscal" maxlength="5" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="Correo" class="form-label w-100">Correo Electrónico</label>
        <input #correo="ngModel" type="text" [ngModelOptions]="{standalone: true}"
               [(ngModel)]="requestTicket.receptor.addendaReceptor.email"
               pattern="^[^@]+@[^@]+\.[a-zA-Z]{2,}$"
               class="form-control {{correo.invalid?'is-invalid':'is-valid'}}"
               required minlength="4">
        <small class="text-danger" *ngIf="correo.invalid && correo.touched">*correo electrónico inválido</small>
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="ConfirmarCorreo" class="form-label w-100">Confirmar Correo Electrónico </label>
        <input type="email" class="form-control {{((requestTicket.receptor.addendaReceptor.email?.toUpperCase()!=mailConfirm.toUpperCase()) || mailConfirm == '' || correoConfirm.invalid ) ? 'is-invalid' : 'is-valid'}}"
               id="ConfirmarCorreo"
               required [(ngModel)]="mailConfirm"
               [ngModelOptions]="{standalone: true}"
               pattern="^[^@]+@[^@]+\.[a-zA-Z]{2,}$"
               (ngModelChange)="mailConfirm = $event.toLowerCase()"
               #correoConfirm="ngModel">

        <small class="text-danger" *ngIf="correoConfirm.invalid && correoConfirm.touched">*correo electrónico inválido</small>
      </div>


      <!--
  <div class="col-md-4">
    <label for="inputState" class="form-label">State</label>
    <select id="inputState" class="form-select">
      <option selected>Adquisicion de Mercancias.</option>
      <option>Gastos en General.</option>
      <option>Sin efectos Fiscales.</option>
    </select>
  </div>


  <div class="col-12">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="gridCheck">
      <label class="form-check-label" for="gridCheck">
        Completar INE
      </label>
    </div>
  </div>

  -->


      <div class="row mt-5">
        <div *ngIf="showError" class="alert alert-danger">

          Favor de completar correctamente los siguientes campos
          <ul>
            <li *ngFor="let e of allErrors">
              {{e}}
            </li>
          </ul>

        </div>




      </div>
      <div class="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center flex-wrap-reverse  mt-5 mb-5 p-0">
        <button class="btn btn-secondary  col-lg-2 col-md-3 col-12  heightButton mt-lg-0 mt-md-0  mt-3" type="button"
                (click)="clickReturn()">
          Regresar
        </button>
        <button class="btn btn-primary col-lg-2 col-md-3 col-12 ms-lg-5 ms-md-5   buttonSubmit heightButton"
                type="button" (click)="clickContinue()">
          Continuar
        </button>
      </div>
    </form>
  </div>


</div>

<!-- <button (click)="test()"> Mostrar Alerta</button> -->


<app-modal [isOpen]="modalInfo.isOpen"
[title]="modalInfo.titleModal"
[imgBody]="modalInfo.imgModal"
(onClose)="closeModal()">{{ modalInfo.content }}</app-modal>

<app-action-modal [isOpen]="actionModalInfo.isOpen"
                  [titleModal]="actionModalInfo.titleModal"
                  [contentModal]="actionModalInfo.content"
                  (onClose)="actionModalInfo.onCloseEvent()">{{ actionModalInfo.content }}</app-action-modal>
