import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';

import { InvoiceSearchTicketComponent } from './InvoiceSearch/invoice-search-ticket.component';
import { FAQComponent } from './faq/faq.component';
import { CustomerContactComponent } from './CustomerContact/customer-contact.component';

const routes: Routes = [
  {
    path: '',
    component: InvoiceSearchTicketComponent,
  }
];

@NgModule({
  declarations: [
    InvoiceSearchTicketComponent,
    FAQComponent,
    CustomerContactComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),

    HttpClientModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    InvoiceSearchTicketComponent
    ],
})
export class ModulesModule { }
