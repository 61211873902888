import { configSamsonite } from "src/configs/samsonite.config";
import { Company, EnviromentType, Environment } from "../interfaces/env.default.interface";


export const environment:Environment = {
  enviromentType: EnviromentType.Dev,
  company: Company.Samsonite,
  apiUrl:"https://samsonite.masfacturaweb.com.mx/",
  configuration: configSamsonite,
  companyCode: "SAMSONITE"
};
