import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.css']
})
export class ActionModalComponent {
  @Input() isOpen: boolean = false;
  @Input() titleModal: string = '';
  @Input() contentModal: string = '';
  @Input() showCancelButton: boolean = false;
  @Input() cancelButtonText: string = "Cancelar";
  @Input() confirmButtonText: string = "Confirmar";
  @Output() onClose = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
}
