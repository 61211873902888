import { FormsModule } from '@angular/forms';
import { Component, EventEmitter, Output, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as bootstrap from 'bootstrap';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { AppComponent } from '../../../app/app.component';
import { environment } from 'src/environments/env.default';
import { BaseService } from 'src/app/shared/services/base.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { QuestionService } from '../Invoices/services/question.service';
import { Router } from '@angular/router';
import { Company } from 'src/interfaces/env.default.interface';

@Component({
  selector: 'app-invoice-search-ticket',
  templateUrl: './invoice-search-ticket.component.html',
  styleUrls: ['./invoice-search-ticket.component.css'],
  providers: [QuestionService],
})
export class InvoiceSearchTicketComponent implements OnInit, AfterViewInit {
  public CONF: any = ConfigService.CONF; // load configuration by enviroment
  public showSpinnerProcess: boolean = false; // Used to show the spinner
  public actionModalInfo = {
    isOpen: false,
    titleModal: '',
    content: '',
    showCancelButton: false,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Confirmar',
    onCloseEvent: () => {},
    onCancelEvent: () => {},
  };

  public showSearchButton: boolean = false;
  public searchValue: string = '';
  public urlPdf: string = '';
  public urlXml: string = '';
  public message: string = '';
  public error: boolean = false;
  public showticket: boolean = false;
  public tipoForm: string = '';
  public existsPdf: boolean = false;
  public existsXml: boolean = false;

  public selectedOption: number = 1; // Valor predeterminado

  //Forma 1
  public uuidForm: string = '';

  //Forma 2
  public ticketForm: string = '';
  public sucursalForm: string = '';
  public montoForm: string = '';
  public canalVentaForm: string = '';
  public fechaVentaForm: string = '';

  //Forma 3
  public rfcReceptorForm: string = '';
  public serieForm: string = '';
  public folioForm: string = '';
  public fechaEmiForm: string = '';

  constructor(
    private http: HttpClient,
    private app: AppComponent,
    private cdr: ChangeDetectorRef,
    private config: ConfigService,
    private router: Router,
    private qService: QuestionService
  ) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.config.getConfigurationAsync().subscribe((configuration) => {
      this.CONF = ConfigService.CONF;
      this.cdr.detectChanges();
    });
  }

  public ticketShow(): void {
    this.showticket = !this.showticket;
  }

  public searchValueChange(): void {
    this.showSearchButton = false;
  }

  public eraseSearch(): void {
    this.showSearchButton = false;
    this.searchValue = '';
    this.uuidForm = '';
    this.ticketForm = '';
    this.sucursalForm = '';
    this.montoForm = '';
    this.canalVentaForm = '';
    this.fechaVentaForm = '';
  }

  onListenFormValue(data: any) {
    // Case: Farmacias
    if (environment.company == Company.Farmacias) {
      //this.businessLogicFarmacias(data);
    }
    // Case: LVH
    else if (environment.company == Company.Lvh) {
      // ...
    }
    // Case: Samsonite
    else {
      this.businessLogicSamsonite(data);
    }
  }

  businessLogicSamsonite(data: any) {
    if (!this.validateTicketForm()) {
      this.showWaitModal('Datos inválidos', "LLene adecuadamente los campos de búsqueda");
      return;
    }

    this.showSpinnerProcess = true;
    this.cdr.detectChanges();

    const request = {
      UUID: this.uuidForm,
      NumTicket: this.ticketForm,
      NumSucursal: this.sucursalForm,
      MontoTicket: this.montoForm,
      CanalVenta: this.canalVentaForm,
      FechaVenta: this.fechaVentaForm,
      RfcReceptor: this.rfcReceptorForm,
      Serie: this.serieForm,
      Folio: this.folioForm,
      FechaEmision: this.fechaEmiForm,
      TipoConsulta: this.selectedOption,
      CompanyCode: environment.companyCode,
    };

    // Construir el Query String con los parámetros
    const params = new HttpParams({ fromObject: request });

    // URL de la API
    const url = this.app.env.apiUrl + 'ConsultarFacturas/Consultar';

    // Headers de la solicitud
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // Realizar la llamada GET con los parámetros en el Query String
    this.http
      .get<any>(url, { headers, params, withCredentials: true })
      .subscribe(
        (request) => {
          this.showSpinnerProcess = false;

          const actionToDo: string = request.answerCode;
          if (actionToDo === '001') {
            // this.urlPdf = request.pdfUrl;
            // this.urlXml = request.xmlUrl
            this.showSearchButton = true;
            this.getDownloadForm(request);
          }
          if (actionToDo === '101') {
            this.showWaitModal('Ticket no válido', request.message);
          }
        },
        (error) => {
          this.showSpinnerProcess = false;
          console.log(error);

          this.qService.updateResponseValidation({
            error: true,
            message: 'Ha ocurrido el siguiente error: ' + error.message,
          });
        }
      );
  }

  showWaitModal(title: string, message: string): void {
    this.actionModalInfo = {
      isOpen: true,
      titleModal: title,
      content: message,
      showCancelButton: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      onCloseEvent: this.onWaitTicket.bind(this),
      onCancelEvent: () => {},
    };
  }

  onWaitTicket(): void {
    this.actionModalInfo = {
      isOpen: false,
      titleModal: '',
      content: '',
      showCancelButton: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      onCloseEvent: () => {},
      onCancelEvent: () => {},
    };
    this.router.navigate(['/buscarfactura']);
  }

  getDownloadForm(request: any): void {
    if (request.pdfUrl) {
      this.existsPdf = true;
      this.urlPdf = `${this.app.env.apiUrl}ConsultaCif/Descarga/?qr=${request.pdfUrl}`;
    } else {
      this.existsPdf = false;
    }
    if (request.xmlUrl) {
      this.existsXml = true;
      this.urlXml = `${this.app.env.apiUrl}ConsultaCif/Descarga/?qr=${request.xmlUrl}`;
    } else {
      this.existsXml = false;
    }
  }

  validateTicketForm() {
    if (Number.parseInt(this.selectedOption.toString())  === 1) {
      if (
        this.ticketForm.trim() === '' ||
        this.sucursalForm.trim() === '' ||
        this.sucursalForm.trim() === '' ||
        this.montoForm === '' ||
        this.canalVentaForm.trim() === '' ||
        this.fechaVentaForm.trim() === ''
      ) {
        return false;
      }
      return true;
    }

    if (Number.parseInt(this.selectedOption.toString()) === 2) {
      if (this.uuidForm.trim() === '') {
        return false;
      }
      return true;
    }

    return true;
  }
}
