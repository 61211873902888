import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/env.default';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  private apiUrl = environment.apiUrl; //environment.baseUrl; // Define la URL base de la API

  constructor(private http: HttpClient) {
    
  }

  // Método genérico para realizar solicitudes GET
  get<T>(url: string, params?: HttpParams): Observable<T> {
    const URL = this.apiUrl + url;
    
    return this.http.get<T>(URL, { params });
  }

  // Método genérico para realizar solicitudes POST
  post<T>(url: string, data: any): Observable<T> {
    const URL = this.apiUrl + url;
    
    return this.http.post<T>(URL, data);
  }

  // Método genérico para realizar solicitudes PUT
  put<T>(url: string, data: any): Observable<T> {
    const URL = this.apiUrl + url;
    
    return this.http.put<T>(URL, data);
  }

  // Método genérico para realizar solicitudes DELETE
  delete<T>(url: string): Observable<T> {
    const URL = this.apiUrl + url;
    
    return this.http.delete<T>(URL);
  }
}
