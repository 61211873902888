import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerContactComponent } from './modules/CustomerContact/customer-contact.component';
import { InvoiceSearchTicketComponent } from './modules/InvoiceSearch/invoice-search-ticket.component';
import { HomeInvoiceComponent } from './modules/home-invoice/home-invoice.component';
import { FAQComponent } from './modules/faq/faq.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
    path: 'home',
    component: HomeInvoiceComponent
  },

    {
      path: 'creafactura',

      loadChildren: () => import('./modules/Invoices/invoice.module').then(m => m.InvoiceModule)
    },
    {
      path: 'buscarfactura',
      component: InvoiceSearchTicketComponent
    },
    {
      path: 'faq',
      component: FAQComponent
    },
    {
      path: 'contacto',
      component: CustomerContactComponent
    },
    {
      path: '', redirectTo: 'home', pathMatch: 'full'
    },
    {
      path: '**', redirectTo: 'home', pathMatch: 'full'
    },


  ];

  @NgModule({
    imports: [
      FormsModule,
      CommonModule,
      RouterModule.forRoot(routes)],
    exports: [RouterModule,]
  })
export class AppRoutingModule { }
