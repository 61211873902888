<div class="container mt-5">
  <form (ngSubmit)="onSubmit()" [formGroup]="qService.form">
    <!-- Form -->    
    <div class="row">
      <app-question [question]="question" [form]="qService.form" class="col-md-6 col-lg-6 col-sm-12"  *ngFor="let question of questions"></app-question>
    </div>

    <!-- Button -->
    <div class="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center  mt-5 mb-5 p-0 ">
      <button [disabled]="!qService.form.valid"  id="btnContinuar" type="submit"
              class="btn btn-primary col-lg-2 col-md-3 col-12 buttonSubmit heightButton">
              Continuar
      </button>
    </div>
  </form>

  <!-- Response mesage -->
  <div  class="form-row">
    <div class="col-md-12">
      <!-- Message error -->
      <div *ngIf="responseValidation?.error">
        <div class="alert alert-danger m-0 font-weight-bold" role="alert">
          {{responseValidation?.message}}
        </div>
      </div>

      <!-- Message: Ticket refacturado --> 
      <div class="d-flex flex-column text-center mt-5" *ngIf="responseValidation?.ticketFacturadoAlert">
        <div class="alert alert-success m-0" role="alert">
          <i class="bi bi-download"></i>  El ticket ya fue facturado. Aquí puede descargar su factura en formato PDF y XML.
        </div>
        <div>
          <a style="color:white;" class="btn btn-danger col-12 col-lg-3 col-md-8 col-sm-12 mt-5 mb-5 heightButton " target="_blank" href="{{responseValidation?.urlPdf}}"><i class="bi bi-filetype-pdf"></i> Descargar PDF</a>
        </div>
        <div>
          <a style="color:white;" class="btn btn-danger col-12 col-lg-3 col-md-8 col-sm-12 mb-5 heightButton" target="_blank" href="{{responseValidation?.urlXml}}"><i class="bi bi-filetype-xml"></i> Descargar XML</a>
        </div>
      </div>
      
    </div>
  </div>

</div>
<!-- 
<p *ngFor="let _question of questions">
  {{ this.qService.form.controls[_question.key].valid | json }} - {{ this.qService.form.controls[_question.key].touched | json }} - {{ this.qService.form.controls[_question.key].value | json }}
</p> -->