<div class="containerContact pb-5 h-auto">

  <div class="containerSpinner" *ngIf="showSpinnerProcess">
    <div class="spinner-grow text-primary m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary  m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </symbol>
  </svg>
  <div class="alert alert-light alert-dismissible fade show alert-dismissible-serch text-primary-emphasis" role="alert">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill" /></svg>
    ¡Recuerda! Para enviar el correo, por favor, ingresa los datos solicitados a continuación.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div class="container pb-5 pt-5  containerContactHeader ">
    <div class="containerContactHeaderText"><h3 class="text-center h2style">Contáctanos</h3><br><p>Tu opinión es fundamental para nosotros. Estamos ansiosos por recibir tus comentarios y brindarte la mejor atención posible. Puedes contactarnos de las siguientes maneras</p></div>
    <div class="containerContactHeaderImg"><img [src]="CONF.staticContent.modules.customerContact.img_contact" alt="Imgen Tablet" class="contactHeaderImg"></div>
  </div>
  <div class="container d-flex row m-auto pt-5 ">
    <form class="row gy-4 gx-4 m-0">

      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="exampleFormControlInput1" class="form-label col">
          Nombre
        </label>
        <input type="text" class="form-control" id="exampleFormControlInput1" required [(ngModel)]="nameForm" name="name">
        <small id="emailHelp" class="form-text text-muted">Introduce tu nombre completo.</small>
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="inputAddress" class="form-label w-100">Correo</label>
        <input type="email" class="form-control" id="inputAddress" required [(ngModel)]="emailForm" name="email">
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="inputAddress2" class="form-label">Ticket</label>
        <input type="text" class="form-control" id="inputAddress2" required [(ngModel)]="ticketForm" name="ticket">
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="inputCity" class="form-label">Sucursal</label>
        <input type="text" class="form-control" id="inputCity" required [(ngModel)]="sucursalForm" name="sucrusal">
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="inputAddress" class="form-label">Asunto</label>
        <input type="text" class="form-control" id="inputAddress" required [(ngModel)]="subjetForm" name="asunto">
      </div>
      <div class="col-md-6 col-lg-4 col-sm-12 ">
        <label for="inputCity" class="form-label">Fecha</label>
        <input type="date" class="form-control" id="inputCity" required [(ngModel)]="dateForm" name="fecha">
        <small id="emailHelp" class="form-text text-muted">Fecha del Ticket.</small>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12">
        <label for="exampleFormControlTextarea1" class="form-label">Escribe tu mensaje</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" [(ngModel)]="mensajeForm" name="texto"></textarea>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12">
        <button class="btn btn-primary col-lg-2 col-md-3 col-12 ms-lg-5 ms-md-5   buttonSubmit heightButton" type="button" (click)="onListenFormValue($event)">Enviar</button>
      </div>
      </form>
  </div>

  <app-action-modal 
  [isOpen]="actionModalInfo.isOpen" 
  [titleModal]="actionModalInfo.titleModal"
  [contentModal]="actionModalInfo.content" 
  (onClose)="actionModalInfo.onCloseEvent()">
  {{ actionModalInfo.content}}
</app-action-modal>
